import { batch, useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import {
  getPartner,
  getPartners,
  createPartner,
  patchPartnerData,
  getAmountInbodyTests,
  deletePartnerPass,
  addPartnerAggregator,
  removePartnerAggregator,
} from 'network/services/partners.service';

import {
  setPartnerAggregators,
  setPartnerData,
} from 'redux/slices/partner.slice';
import { setModalStatus } from '../redux/slices/modal.slice';
import { PARTNER_AGGREGATORS } from '../utils/constants';

// ---- TRAER INFORMACIÓN DEL PARTNER ----
export const usePartner = id => {
  const dispatch = useDispatch();

  return useQuery(['GET_PARTNER', id], () => getPartner(id), {
    onSuccess: ({ data }) => {
      const { inbodyScheduleTimes, closingDates, user, ...restData } = data;
      const { permissions = [] } = user;
      const mappedTimes = inbodyScheduleTimes.map(
        ({ _id, ...restItem }, index) => ({
          id: `open-time-${index}`,
          data: { ...restItem },
        }),
      );
      const mappedDates = closingDates.map((item, index) => ({
        id: `close-date-${index}`,
        data: { startDate: item.startDate, endDate: item.endDate },
      }));
      const aggregators = PARTNER_AGGREGATORS.map(aggregator => ({
        name: aggregator,
        enabled: permissions.includes(aggregator),
      }));
      batch(() => {
        dispatch(setPartnerAggregators(aggregators));
        dispatch(
          setPartnerData({
            inbodyScheduleTimes: mappedTimes,
            closingDates: mappedDates,
            ...restData,
          }),
        );
      });
    },
  });
};

// ---- TRAER LISTADO DE PARTNERS ----
export const usePartners = params =>
  useQuery('GET_PARTNERS', () => getPartners(params));

// ---- TRAE LA CANTIDAD DE PRUEBAS INBODY REALIZADAS POR PERIODO ----
export const useGetAmountInbodyTests = params => {
  return useQuery('GET_AMOUNT_INBODY_TESTS', () =>
    getAmountInbodyTests(params),
  );
};

// ---- CREAR NUEVO PARTNER ----
export const usePartnerRegister = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(createPartner, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PARTNERS');
      enqueueSnackbar('Se creó con éxito el nuevo partner', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- ACTUALIZAR INFORMACIÓN DEL PARTNER ----
export const usePartnerData = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchPartnerData, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PARTNER');
      enqueueSnackbar('Se actualizó con éxito la información del partner', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- QUITA UN PASE INBODY A UN PARTNER ----
export const useDeletePartnerInbodyPass = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(deletePartnerPass, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PARTNERS');
      enqueueSnackbar('Se quitó con éxito el pase', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intente más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'REMOVE_PASSES',
          isActive: false,
        }),
      );
    },
  });
};

// ---- AGREGA UN AGREGADOR AL PARTNER ----
export const useAddPartnerAggregator = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(addPartnerAggregator, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PARTNER');
      enqueueSnackbar(
        'Se actualizó correctamente la información del agregador',
        {
          variant: 'success',
        },
      );
    },
    onError: () => {
      enqueueSnackbar(
        'No se pudo actualizar los agregadores, intenta más tarde.',
        {
          variant: 'error',
        },
      );
    },
  });
};

// ---- QUITA UN AGREGADOR AL PARTNER ----
export const useRemovePartnerAggregator = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(removePartnerAggregator, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PARTNER');
      enqueueSnackbar(
        'Se actualizó correctamente la información del agregador',
        {
          variant: 'success',
        },
      );
    },
    onError: () => {
      enqueueSnackbar(
        'No se pudo actualizar los agregadores, intenta más tarde.',
        {
          variant: 'error',
        },
      );
    },
  });
};
