import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { nutritionistSelector } from 'redux/selectors/nutritionist.selector';
import { useToggleVirtualAgenda } from 'hooks/nutritionists.hook';

const ToggleAgendaVirtualPanel = () => {
  const nutritionist = useSelector(nutritionistSelector);

  const { mutate: toggleVirtualAgenda } = useToggleVirtualAgenda();

  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    toggleVirtualAgenda({
      id: nutritionist?._id ?? '',
      data: {
        canUseAgenda: !nutritionist?.nutritionistProfile?.canUseAgenda,
      },
    });
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'TOGGLE_AGENDA_VIRTUAL',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="body2">
        ¿Estás seguro que deseas{' '}
        {nutritionist?.nutritionistProfile?.canUseAgenda
          ? 'desactivar'
          : 'activar'}{' '}
        la agenda virtual para este nutriólogo?
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        mt={2}
      >
        <Button onClick={onConfirmHandler} color="primary" variant="contained">
          Confirmar
        </Button>
        <Button onClick={onCancelHandler} color="primary" variant="outlined">
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default ToggleAgendaVirtualPanel;
