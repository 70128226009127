import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { usePatchInterestFree } from 'hooks/admin.hook';
import { setModalStatus } from 'redux/slices/modal.slice';

const ConfirmInterestFreeMonthPanel = () => {
  const { interestPayload } = useSelector(state => state.discounts);

  const { mutate, isLoading } = usePatchInterestFree();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    mutate({
      id: interestPayload.id,
      data: interestPayload.data,
    });
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CONFIRM_SET_INTEREST_FREE_MONTH',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>
        ¿Estás seguro que deseas{' '}
        {!interestPayload.checked ? 'desactivar' : 'activar'} esta mensualidad?
      </Typography>

      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmInterestFreeMonthPanel;
