import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';

import { WellnubDateInput, WellnubImageViewer } from '@wellnub/web-common';

import DeleteIcon from '@material-ui/icons/Delete';

import { setInbodyResultFile } from 'redux/slices/inbodyResultFile.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

import { useUploadInBodyResults } from 'hooks/patient.hook';

import { FormSchema } from './InbodyResultViewerPanel.schema';

const InbodyResultViewerPanel = () => {
  const { file, patientID } = useSelector(state => state.inbodyResultFile);

  const { mutate: uploadResults, isLoading } = useUploadInBodyResults();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      date: new Date(),
    },
  });

  const dispatch = useDispatch();

  const onSubmitHandler = handleSubmit(async data => {
    const imageFile = await fetch(file).then(result => result.blob());
    const formData = new FormData();
    formData.append('testDate', data.date);
    formData.append('file', imageFile);
    formData.append('patientId', patientID);
    uploadResults(formData);
  });

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'INBODY_RESULT_VIEWER',
        isActive: false,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setInbodyResultFile(null));
    };
  }, [dispatch]);

  return (
    <Box>
      <form onSubmit={onSubmitHandler}>
        <Grid container spacing={4}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <WellnubDateInput
              fullWidth
              name="date"
              label="Fecha"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              control={control}
            />
          </Grid>
        </Grid>
        <Box
          component="section"
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? 'Cargando...' : 'Aceptar'}
          </Button>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            color="primary"
            onClick={onCancelHandler}
          >
            Eliminar archivo
          </Button>
        </Box>
        <Box component="section" mt={2}>
          <WellnubImageViewer url={file} />
        </Box>
      </form>
    </Box>
  );
};

export default InbodyResultViewerPanel;
