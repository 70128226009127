import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { useCreateCheckInInbody } from 'hooks/admin.hook';
import { setModalStatus } from 'redux/slices/modal.slice';

const CreateInbodyCheckinPanel = () => {
  const { idToCancel } = useSelector(state => state.upcomingEvents);

  const { mutate: createCheckIn, isLoading } = useCreateCheckInInbody();

  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    createCheckIn(idToCancel);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CREATE_INBODY_CHECKIN',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Deseas hacer el check-in de este paciente?</Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmHandler}
          disabled={isLoading}
        >
          Confirmar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default CreateInbodyCheckinPanel;
