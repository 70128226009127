import { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setIdToCancel } from 'redux/slices/upcomingEvents.slice';
import { setWNTargetNotification } from 'redux/slices/notifications.slice';
import { notificationTargetSelector } from 'redux/selectors/notifications.selector';

const useStyles = makeStyles(theme => ({
  row: {
    transition: 'background-color 250ms linear',
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  action: {
    color: theme.palette.action.main,
  },
  focused: {
    backgroundColor: '#b6dbb6 !important',
  },
  actionsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UpcommingInbodyRow = ({ inbody }) => {
  const [isFocused, setIsFocused] = useState(false);

  const target = useSelector(notificationTargetSelector);

  const elementRef = useRef(null);

  const classes = useStyles();

  const dispatch = useDispatch();

  const onCheckInClickHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'CREATE_INBODY_CHECKIN',
          isActive: true,
        }),
      );
      dispatch(setIdToCancel(inbody.id));
    });
  };

  const onCancelCheckInClickHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'CANCEL_UPCOMING_INBODY',
          isActive: true,
        }),
      );
    });
    dispatch(setIdToCancel(inbody.id));
  };

  useEffect(() => {
    if (target.id === inbody.id) {
      setTimeout(() => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
        setIsFocused(true);
      }, 1000);
      setTimeout(() => {
        setIsFocused(false);
        dispatch(
          setWNTargetNotification({
            id: '',
            type: '',
          }),
        );
      }, 4500);
    }
  }, [target]);

  return (
    <TableRow
      ref={elementRef}
      className={`${classes.row} ${isFocused && classes.focused}`}
    >
      <TableCell align="center">
        <Typography variant="body2">{inbody.data.type}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{inbody.data.name}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{inbody.data.phoneNumber}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{inbody.data.partner}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{inbody.data.nutritionist}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography variant="body2">{inbody.data.schedule}</Typography>
      </TableCell>
      <TableCell align="center">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Tooltip title="Check In">
              <IconButton
                className={classes.action}
                size="small"
                onClick={onCheckInClickHandler}
              >
                <AssignmentTurnedInIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Cancelar">
              <IconButton
                className={classes.action}
                size="small"
                onClick={onCancelCheckInClickHandler}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default UpcommingInbodyRow;