import { wellnub } from '../wellnub.api';

export const getNutritionist = id =>
  wellnub.get(`/v1/admin/user/nutritionist/${id}`);

export const getNutritionists = () =>
  wellnub.get('/v1/admin/user/nutritionist');

export const uploadPatientDataBase = async payload => {
  const { data } = await wellnub.post(
    `/v1/admin/patient/batch/${payload.id}`,
    payload.data,
  );
  return data;
};

export const removeNutritionistPasses = async payload => {
  const { data } = await wellnub.delete(
    `/v1/admin/inbodyPass/nutritionist/${payload.id}`,
    {
      data: payload.data,
    },
  );
  return data;
};

export const addNutritionistPasses = async payload => {
  const { data } = await wellnub.post(
    '/v1/admin/payment/addToNutritionist ',
    payload,
  );
  return data;
};

export const toggleVirtualAgenda = async payload => {
  const { data } = await wellnub.patch(
    `/v1/admin/user/nutritionist/agenda/${payload.id}`,
    payload.data,
  );
  return data;
};
