import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  cellHeader: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
  },
  action: {
    padding: theme.spacing(1),
    color: theme.palette.action.main,
  },
  actionsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
