import { Link } from 'react-router-dom';

import { List, ListItem, Drawer, Typography } from '@material-ui/core';

import { sideMenuLinks } from 'utils/constants';

import { useStyles } from './SideMenu.styles';

const SideMenu = ({ isOpen, closeHandler }) => {
  const classes = useStyles();

  return (
    <Drawer open={isOpen} onClose={closeHandler}>
      <List component="ul" className={classes.list}>
        {sideMenuLinks.map(link => (
          <Link
            key={link.url}
            to={link.url}
            className={classes.link}
            onClick={closeHandler}
          >
            <ListItem button component="li" className={classes.item}>
              <Typography variant="body2" className={classes.text}>
                {link.name}
              </Typography>
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
};

export default SideMenu;
