import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  notification: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: theme.spacing(1.5),
    color: ({ actionable, wasSeen }) =>
      actionable && wasSeen ? '#727272' : '#0b1015',
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: ({ actionable, wasSeen }) =>
      actionable && wasSeen ? '#727272' : theme.palette.primary.dark,
  },
  actionable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));