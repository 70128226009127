import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useRemoveNutritionistPasses } from 'hooks/nutritionists.hook';

const RemoveNutritionistPasses = () => {
  const [passes, setPasses] = useState(1);

  const { name, id } = useSelector(state => state.client);

  const { mutate: removePasses, isLoading } = useRemoveNutritionistPasses();

  const onPassesChangeHandler = event => {
    const { value } = event.target;
    setPasses(+value);
  };

  const onSubmitHandler = () => {
    removePasses({
      id,
      data: {
        numberOfPasses: passes,
      },
    });
  };

  return (
    <Box>
      <Typography align="center" style={{ marginBottom: 16 }}>
        Confirma los pases que deseas retirar a {name}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        type="number"
        label="Pases"
        value={passes}
        onChange={onPassesChangeHandler}
        InputProps={{
          inputProps: {
            min: 1,
          },
        }}
      />
      <Box textAlign="center" mt={2}>
        <Button
          onClick={onSubmitHandler}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Confirmar
        </Button>
      </Box>
    </Box>
  );
};

export default RemoveNutritionistPasses;
