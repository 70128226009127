import { wellnub } from '../wellnub.api';

export const getPartners = params =>
  wellnub.get(
    `/v1/admin/user/partner?month=${params.month}&year=${params.year}`,
  );

export const getAmountInbodyTests = async params => {
  const { data } = await wellnub.get(
    `/v1/admin/inbodyPass/total?month=${params.month}&year=${params.year}`,
  );
  return data;
};

export const getPartner = id => wellnub.get(`/v1/admin/inbodyLocation/${id}`);

export const createPartner = data =>
  wellnub.post('/v1/admin/user/partner', data);

export const patchPartnerData = payload =>
  wellnub.patch(`/v1/admin/inbodyLocation/${payload.id}`, payload.data);

export const deletePartnerPass = id =>
  wellnub.delete(`/v1/admin/inbodyPass/partner/${id}`);

export const addPartnerAggregator = payload =>
  wellnub.post(`/v1/admin/user/partner/permission/${payload.id}`, payload.data);

export const removePartnerAggregator = payload => {
  console.log('Payload del delete', payload);
  return wellnub.delete(`/v1/admin/user/partner/permission/${payload.id}`, {
    data: payload.data,
  });
};
