import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import Button from '../../../Button/Button.component';

import { setModalStatus } from 'redux/slices/modal.slice';

import { usePartnerData } from 'hooks/partners.hook';

const DeleteScheduleCloseTime = () => {
  const { data, scheduleTimeId } = useSelector(state => state.partner);

  const { mutateAsync, isLoading } = usePartnerData();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    mutateAsync({
      id: data._id,
      data: {
        closingDates: data.closingDates
          .filter(item => item.id !== scheduleTimeId)
          .map(date => date.data),
      },
    }).then(() => {
      dispatch(
        setModalStatus({
          isActive: false,
          name: 'DELETE_SCHEDULE_CLOSE_TIME',
        }),
      );
    });
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        isActive: false,
        name: 'DELETE_SCHEDULE_CLOSE_TIME',
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Estás seguro que deseas borrar esta entrada?</Typography>
      <Typography>
        Este día y horario se habilitarán para los clientes que deseen agendar
        análisis
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-around"
      >
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          loading={isLoading}
        >
          Confirmar
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteScheduleCloseTime;
