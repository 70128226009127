import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campaings: [],
  interestFree: [],
  activeID: '',
  interestPayload: {},
};

const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    setDiscountCampaings: (state, action) => {
      state.campaings = action.payload;
    },
    setActiveID: (state, action) => {
      state.activeID = action.payload;
    },
    setInterestFree: (state, action) => {
      state.interestFree = action.payload;
    },
    setInterestPayload: (state, action) => {
      state.interestPayload = action.payload;
    },
  },
});

export const {
  setDiscountCampaings,
  setActiveID,
  setInterestFree,
  setInterestPayload,
} = discountsSlice.actions;
export const DiscountsReducer = discountsSlice.reducer;
