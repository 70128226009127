import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';

import { useVerifyClient } from 'hooks/admin.hook';

import { setModalStatus } from 'redux/slices/modal.slice';

const ConfirmVerifyPatientPanel = () => {
  const { data } = useSelector(state => state.patient);

  const { mutate: verifyClient, isLoading } = useVerifyClient();

  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    verifyClient(data._id);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CONFIRM_VERIFY_PATIENT',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Estás seguro que deseas verificar este paciente?</Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        mt={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmHandler}
          disabled={isLoading}
        >
          Verificar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmVerifyPatientPanel;
