import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  notificationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  papper: {
    maxHeight: 550,
  },
}));
