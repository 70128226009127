import { wellnub } from '../wellnub.api';

export const updatePatient = payload =>
  wellnub.patch(`/v1/admin/patient/${payload.id}`, payload.data);

export const addPatientPasses = payload =>
  wellnub.post('/v1/admin/payment/addToPatient', payload);

export const deletePatient = async id => {
  const { data } = await wellnub.delete(`/v1/admin/patient/${id}`);
  return data;
};

export const uploadInBodyResults = async payload => {
  const { data } = await wellnub.post(`/v1/admin/inbodyResult`, payload);
  return data;
};
