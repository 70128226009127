export const palette = {
  type: 'light',
  primary: {
    dark: '#000000',
    main: '#0b1015',
    light: '#1f242a',
  },
  secondary: {
    dark: '#eceeec',
    main: '#f2faf4',
    light: '#ffffff',
  },
  action: {
    main: '#377990',
    light: '#82b5c3',
  },
  text: {
    primary: '#464b51',
  },
};
