export const generateCSV = data => {
  const keys = Object.keys(data[0]);
  const commaSeparatedString = [
    keys.join(','),
    data.map(row => keys.map(key => row[key]).join(',')).join('\n'),
  ].join('\n');
  return new Blob([commaSeparatedString], { type: 'text/csv;charset=utf-8;' });
};

export const validatePhoneNumber = value => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber) {
    if (value.includes('+52')) {
      const rest = value.substring(3);
      return rest.length === 10 || 'El número debe ser de 10 dígitos';
    }
    if (value.includes('+1')) {
      const [, ...rest] = value.split(' ');
      const last = rest.join('').replace(/[^\d]/g, '');
      return last.length === 10 || 'El número debe ser de 10 dígitos';
    } else {
      return 'Selecciona un código de área válido';
    }
  } else {
    return 'Este campo es obligatorio';
  }
};
