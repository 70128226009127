import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  temporalID: '',
};

const temporalSlice = createSlice({
  name: 'temporal',
  initialState,
  reducers: {
    setTemporalID: (state, action) => {
      state.temporalID = action.payload;
    },
  },
});

export const { setTemporalID } = temporalSlice.actions;
export const TemporalReducer = temporalSlice.reducer;
