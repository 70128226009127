import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector, batch } from 'react-redux';

import { isEmpty } from 'lodash';
import { format } from 'date-fns';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  WellnubTable,
  WellnubTimeInput,
  WellnubCheckboxInput,
} from '@wellnub/web-common';

import Button from '../../Button/Button.component';

import { DAYS } from 'utils/constants';

import { setScheduleTimeId } from 'redux/slices/partner.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

import { usePartnerData } from 'hooks/partners.hook';

const days = [
  { label: 'Lu', value: 1 },
  { label: 'Ma', value: 2 },
  { label: 'Mi', value: 3 },
  { label: 'Ju', value: 4 },
  { label: 'Vi', value: 5 },
  { label: 'Sa', value: 6 },
  { label: 'Do', value: 0 },
];
const WorkdayScheduleTable = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const [isAllDaySelected, setIsAllDaySelected] = useState(false);

  const { data: partner } = useSelector(state => state.partner);

  const { mutateAsync, isLoading } = usePartnerData();

  const { getValues, control, reset } = useForm({
    defaultValues: {
      days: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      startTime: new Date(),
      endTime: new Date(),
    },
  });

  const dispatch = useDispatch();

  const HEADERS = ['Acciones', 'Horario', 'Días de la semana'];

  const ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        batch(() => {
          dispatch(
            setModalStatus({
              isActive: true,
              name: 'DELETE_SCHEDULE_OPEN_TIME',
            }),
          );
        });
        dispatch(setScheduleTimeId(id));
      },
    },
  ];

  const onSaveHandler = () => {
    const { startTime, endTime, days } = getValues();
    const mappedDays = Object.keys(days)
      .filter(key => days[key])
      .map(value => +value);
    if (isEmpty(mappedDays)) {
      setError(true);
    } else {
      setError(false);
      const payload = [
        ...(partner?.inbodyScheduleTimes?.map(item => item.data) ?? []),
        {
          days: mappedDays,
          startTime: isAllDaySelected
            ? format(new Date(2022, 0, 1, 0, 0), 'HH:mm')
            : format(startTime, 'HH:mm'),
          endTime: isAllDaySelected
            ? format(new Date(2022, 0, 1, 23, 59), 'HH:mm')
            : format(endTime, 'HH:mm'),
        },
      ];
      mutateAsync({
        id: partner._id,
        data: {
          inbodyScheduleTimes: payload,
        },
      })
        .then(() => {
          reset();
          setIsAllDaySelected(false);
        })
        .catch(e => {});
    }
  };

  const onAllDaySelectedChangeHandler = () => {
    setIsAllDaySelected(prevState => !prevState);
  };

  useEffect(() => {
    if (!isEmpty(partner.inbodyScheduleTimes)) {
      const mappedTimes = partner.inbodyScheduleTimes.map(item => ({
        ...item,
        data: {
          schedule: `${item.data.startTime} - ${item.data.endTime}`,
          days: [...item.data.days]
            .sort()
            .map(day => DAYS[day])
            .join(' - '),
        },
      }));
      setData(mappedTimes);
    } else {
      setData([]);
    }
  }, [partner.inbodyScheduleTimes]);

  return (
    <Box>
      <WellnubTable headers={HEADERS} actions={ACTIONS} rows={data} />
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        <Grid item xs={6} sm={2} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Horario Inicio</Typography>
          <WellnubTimeInput
            fullWidth
            disabled={isAllDaySelected}
            name="startTime"
            control={control}
            inputVariant="outlined"
            rules={{
              required: 'Este campo es requerido',
            }}
          />
        </Grid>
        <Grid item xs={6} sm={2} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Horario Fin</Typography>
          <WellnubTimeInput
            fullWidth
            disabled={isAllDaySelected}
            name="endTime"
            control={control}
            inputVariant="outlined"
            rules={{
              required: 'Este campo es requerido',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Días de la semana</Typography>
          <Grid container>
            {days.map(day => (
              <Grid item xs key={day.label}>
                <WellnubCheckboxInput
                  control={control}
                  label={day.label}
                  name={`days.${day.value}`}
                />
              </Grid>
            ))}
          </Grid>
          <Box>
            {error && (
              <Typography style={{ color: '#ff4262' }}>
                Selecciona una opción para continuar
              </Typography>
            )}
          </Box>
          {/*<WellnubCheckboxGroupInput*/}
          {/*  row*/}
          {/*  fullWidth*/}
          {/*  name="days"*/}
          {/*  control={control}*/}
          {/*  options={WEEK_OPTIONS}*/}
          {/*  rules={{*/}
          {/*    required: 'Este campo es requerido',*/}
          {/*  }}*/}
          {/*  helperText=" "*/}
          {/*/>*/}
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
            loading={isLoading}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      <Box>
        <FormControlLabel
          label="Ingresar todo el día"
          control={
            <Checkbox
              checked={isAllDaySelected}
              onChange={onAllDaySelectedChangeHandler}
              name="isAllWorkdaySelected"
              color="primary"
            />
          }
        />
      </Box>
    </Box>
  );
};

export default WorkdayScheduleTable;
