import {
  Box,
  Button as StyledButton,
  CircularProgress,
} from '@material-ui/core';

import { useStyles } from './Button.styles';

const Button = ({ loading, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <StyledButton disabled={loading} className={classes.button} {...rest}>
        {children}
      </StyledButton>
      {loading && <CircularProgress size={24} className={classes.spinner} />}
    </Box>
  );
};

export default Button;
