import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  getNutritionist,
  getNutritionists,
  removeNutritionistPasses,
  toggleVirtualAgenda,
  uploadPatientDataBase,
} from 'network/services/nutritionists.service';
import { setNutritionistData } from 'redux/slices/nutritionist.slice';
import { setModalStatus } from '../redux/slices/modal.slice';
import { useSnackbar } from 'notistack';

// ---- TRAER INFORMACIÓN DEL NUTRIOLOGO ----
export const useNutritionist = id => {
  const dispatch = useDispatch();

  return useQuery(['GET_NUTRITIONIST', id], () => getNutritionist(id), {
    onSuccess: ({ data }) => {
      dispatch(setNutritionistData(data));
    },
  });
};

// ---- TRAER LISTADO DE NUTRIOLOGOS ----
export const useNutritionists = () =>
  useQuery('GET_NUTRITIONISTS', getNutritionists);

// ---- ACTUALIZA LA BASE DE DATOS DE PACIENTES ----
export const useUploadPatientDataBase = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadPatientDataBase, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONISTS');
    },
  });
};

// ---- ELIMINA PASES PARA NUTRIOLOGO ----
export const useRemoveNutritionistPasses = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(removeNutritionistPasses, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_NUTRITIONISTS');
      dispatch(
        setModalStatus({
          name: 'REMOVE_NUTRITIONIST_PASSES',
          isActive: false,
        }),
      );
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'warning' });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intente de nuevo más tarde',
          { variant: 'error' },
        );
      }
    },
  });
};

// ---- ACTIVA O DESACTIVA LA AGENDA VIRTUAL ----
export const useToggleVirtualAgenda = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(toggleVirtualAgenda, {
    onSuccess: () => {
      enqueueSnackbar('Se actualizó correctamente la información', {
        variant: 'success',
      });
      queryClient.invalidateQueries('GET_NUTRITIONIST');
      dispatch(
        setModalStatus({
          name: 'TOGGLE_AGENDA_VIRTUAL',
          isActive: false,
        }),
      );
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, { variant: 'warning' });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intente de nuevo más tarde',
          { variant: 'error' },
        );
      }
    },
  });
};
