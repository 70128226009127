import { batch, useDispatch, useSelector } from 'react-redux';

import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EventIcon from '@material-ui/icons/Event';

import { useStatus } from 'hooks/common.hook';

import { setActiveClient } from 'redux/slices/client.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

import { STATUSES } from 'utils/constants';

import { Switch, useStyles } from './NutritionistRow.styles';
import { notificationTargetSelector } from '../../../redux/selectors/notifications.selector';
import { useEffect, useRef, useState } from 'react';
import { setWNTargetNotification } from '../../../redux/slices/notifications.slice';

const NutritionistsRow = ({ nutritionist }) => {
  const [isFocused, setIsFocused] = useState(false);

  const { mutate, isLoading } = useStatus('GET_NUTRITIONISTS');

  const target = useSelector(notificationTargetSelector);

  const elementRef = useRef(null);

  const dispatch = useDispatch();

  const { url } = useRouteMatch();

  const { push } = useHistory();

  const classes = useStyles();

  const onShowDetailsClickHandler = id => event => {
    event.preventDefault();
    push(`${url}/${id}`);
  };

  const onSwitchChangeHandler = id => event => {
    const currentStatus = event.target.value;
    mutate({
      id,
      data: {
        status: {
          active: 'inactive',
          inactive: 'active',
          pendingValidation: 'active',
        }[currentStatus],
      },
    });
  };

  const onAddPassesClickHandler = event => {
    event.preventDefault();
    dispatch(
      setActiveClient({
        name: nutritionist.name,
        id: nutritionist._id,
        key: 'GET_NUTRITIONISTS',
      }),
    );
    dispatch(
      setModalStatus({
        name: 'ADD_PASSES',
        isActive: true,
      }),
    );
  };

  const onDeletePassesClickHandler = () => {
    batch(() => {
      dispatch(
        setActiveClient({
          name: nutritionist.name,
          id: nutritionist.id,
        }),
      );
      dispatch(
        setModalStatus({
          name: 'REMOVE_NUTRITIONIST_PASSES',
          isActive: true,
        }),
      );
    });
  };

  useEffect(() => {
    if (target.id === nutritionist.id) {
      setTimeout(() => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
        setIsFocused(true);
      }, 1000);
      setTimeout(() => {
        setIsFocused(false);
        dispatch(
          setWNTargetNotification({
            id: '',
            type: '',
          }),
        );
      }, 4500);
    }
  }, [target]);

  return (
    <TableRow
      key={nutritionist.id}
      className={`${classes.row} ${isFocused && classes.focused}`}
      ref={elementRef}
    >
      {/* --- Nombre --- */}
      <TableCell align="center">
        <Typography variant="body2">
          {nutritionist.name} {nutritionist.lastName}
        </Typography>
      </TableCell>
      {/* --- Email --- */}
      <TableCell align="center">
        <Typography variant="body2">{nutritionist.email}</Typography>
      </TableCell>
      {/* --- Pases --- */}
      <TableCell align="center">
        <Typography variant="body2">{nutritionist.activePasses}</Typography>
      </TableCell>
      {/* --- Acciones --- */}
      <TableCell align="center">
        <Tooltip title="Ver detalles">
          <IconButton
            className={classes.action}
            onClick={onShowDetailsClickHandler(nutritionist.id)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Agregar pases">
          <IconButton
            className={classes.action}
            onClick={onAddPassesClickHandler}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Quitar pases">
          <IconButton
            className={classes.action}
            onClick={onDeletePassesClickHandler}
          >
            <RemoveCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={STATUSES[nutritionist.status].title}>
          <Switch
            color="primary"
            disabled={isLoading}
            value={nutritionist.status}
            checked={STATUSES[nutritionist.status].value}
            onChange={onSwitchChangeHandler(nutritionist.id)}
          />
        </Tooltip>
        <Tooltip
          title={`Agenda virtual: ${
            nutritionist.nutritionistProfile.canUseAgenda
              ? 'Activado'
              : 'Desactivado'
          }`}
        >
          <IconButton
            className={
              nutritionist.nutritionistProfile.canUseAgenda
                ? classes.action
                : classes.disabled
            }
          >
            <EventIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default NutritionistsRow;
