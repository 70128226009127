import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import { addNutritionistPasses } from 'network/services/nutritionists.service';

import { setModalStatus } from 'redux/slices/modal.slice';

export const usePasses = key => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(addNutritionistPasses, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
      enqueueSnackbar('Se agregaron los pases con éxito', {
        variant: 'success',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'ADD_PASSES',
          isActive: false,
        }),
      );
    },
  });
};