import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Avatar, Typography } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WarningIcon from '@material-ui/icons/Warning';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { NOTIFICATION_TYPES } from 'utils/constants';
import { useMarkAsSeenNotification } from 'hooks/admin.hook';
import { useStyles } from './Notification.styles';
import { setWNTargetNotification } from '../../redux/slices/notifications.slice';

const Notification = ({ notification }) => {
  const classes = useStyles({
    actionable: notification.actionable,
    wasSeen: notification.wasSeen,
  });

  const dispatch = useDispatch();

  const { replace } = useHistory();

  const { mutate: markAsSeen } = useMarkAsSeenNotification();

  const Icon = useMemo(
    () =>
      ({
        [NOTIFICATION_TYPES.APPOINTMENT.RESCHEDULE]: EventIcon,
        [NOTIFICATION_TYPES.APPOINTMENT.CANCEL]: EventBusyIcon,
        [NOTIFICATION_TYPES.INBODY.NEW]: AssessmentIcon,
        [NOTIFICATION_TYPES.INBODY.MISSED]: AssignmentLateIcon,
        [NOTIFICATION_TYPES.PATIENT.INBODY_PASS]: LocationOnIcon,
        [NOTIFICATION_TYPES.PATIENT.RESCHEDULE_PASS]: EventNoteIcon,
        [NOTIFICATION_TYPES.GENERAL.ADD_PASSES]: AddCircleIcon,
        [NOTIFICATION_TYPES.REQUESTS.LINK]: GroupAddIcon,
        [NOTIFICATION_TYPES.ADMIN.PARTNER_LOW_STOCK]: WarningIcon,
        [NOTIFICATION_TYPES.ADMIN.NEW_NUTRITIONIST]: PersonAddIcon,
        [NOTIFICATION_TYPES.ADMIN.ACTIVATED_NUTRITIONIST]: VerifiedUserIcon,
        [NOTIFICATION_TYPES.ADMIN.COMPLETED_NUTRITIONIST]: GroupAddIcon,
      }[notification.type] || AnnouncementIcon),
    [notification],
  );

  const onClickHandler = () => {
    console.log('Notificacion: ', notification);
    if (!notification.actionable) {
      return;
    }
    switch (notification.type) {
      case NOTIFICATION_TYPES.PATIENT.RESCHEDULE_PASS:
      case NOTIFICATION_TYPES.PATIENT.INBODY_PASS:
      case NOTIFICATION_TYPES.APPOINTMENT.RESCHEDULE:
        replace('/upcoming-inbodies');
        dispatch(
          setWNTargetNotification({
            type: notification.type,
            id: notification.relatedDataId,
          }),
        );
        markAsSeen(notification._id);
        break;
      case NOTIFICATION_TYPES.ADMIN.NEW_NUTRITIONIST:
      case NOTIFICATION_TYPES.ADMIN.COMPLETED_NUTRITIONIST:
        replace(`/nutritionists`);
        dispatch(
          setWNTargetNotification({
            type: notification.type,
            id: notification.relatedDataId,
          }),
        );
        markAsSeen(notification._id);
        break;
      case NOTIFICATION_TYPES.ADMIN.PARTNER_LOW_STOCK:
        replace('/partners');
        dispatch(
          setWNTargetNotification({
            type: notification.type,
            id: notification.relatedDataId,
          }),
        );
        markAsSeen(notification._id);
        break;
      default:
        break;
    }
  };

  return (
    <article
      className={`${classes.notification} ${
        notification.actionable && classes.actionable
      }`}
      onClick={onClickHandler}
    >
      <Avatar className={classes.avatar}>
        {[
          NOTIFICATION_TYPES.ADMIN.NEW_NUTRITIONIST,
          NOTIFICATION_TYPES.ADMIN.ACTIVATED_NUTRITIONIST,
          NOTIFICATION_TYPES.ADMIN.COMPLETED_NUTRITIONIST,
        ].includes(notification.type) && (
          <Typography variant="caption">N</Typography>
        )}
        {[
          NOTIFICATION_TYPES.INBODY.MISSED,
          NOTIFICATION_TYPES.INBODY.NEW,
          NOTIFICATION_TYPES.PATIENT.RESCHEDULE_PASS,
          NOTIFICATION_TYPES.PATIENT.INBODY_PASS,
        ].includes(notification.type) && (
          <Typography variant="caption">PAX</Typography>
        )}
        {[NOTIFICATION_TYPES.ADMIN.PARTNER_LOW_STOCK].includes(
          notification.type,
        ) && <Typography variant="caption">P</Typography>}
        {![
          NOTIFICATION_TYPES.ADMIN.NEW_NUTRITIONIST,
          NOTIFICATION_TYPES.ADMIN.ACTIVATED_NUTRITIONIST,
          NOTIFICATION_TYPES.ADMIN.COMPLETED_NUTRITIONIST,
          NOTIFICATION_TYPES.INBODY.MISSED,
          NOTIFICATION_TYPES.INBODY.NEW,
          NOTIFICATION_TYPES.PATIENT.RESCHEDULE_PASS,
          NOTIFICATION_TYPES.PATIENT.INBODY_PASS,
          NOTIFICATION_TYPES.ADMIN.PARTNER_LOW_STOCK,
        ].includes(notification.type) && <Icon style={{ fontSize: 18 }} />}
      </Avatar>
      <Typography variant="body2">{notification.text}</Typography>
    </article>
  );
};

export default Notification;