import { batch, useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import {
  deleteUpcomningInbody,
  getClientData,
  getMonthlyReports,
  getAdminActivityLogs,
  getPastInbodies,
  getPatientsAmount,
  getUpcomingInbodies,
  sendInbodyResults,
  getDiscountCampaings,
  postDiscountCampaing,
  deleteDiscountCampaing,
  getInterestFreeList,
  patchInterestFree,
  validateClient,
  postCreateCheckInInbody,
  deleteCancelCheckInInbody,
} from 'network/services/admin.service';

import {
  setPatientData,
  setPatientLoading,
  setPatientVerification,
} from 'redux/slices/patient.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import {
  setTestScheduledLastTwoDays,
  setUpcomingInbodyTests,
} from '../redux/slices/upcomingEvents.slice';
import {
  setActiveID,
  setDiscountCampaings,
  setInterestFree,
} from '../redux/slices/discounts.slice';
import { setInbodyResultID } from '../redux/slices/inbodyResultFile.slice';
import {
  getNotificationCounter,
  getNotifications,
  markAsSeenNotification,
} from '../network/services/admin/notifications.service';
import {
  setWnNotifications,
  setWnUnreadNotifications,
} from '../redux/slices/notifications.slice';
import { NOTIFICATION_TYPES } from '../utils/constants';

export const useGetMonthlyReports = params => {
  return useQuery('GET_MONTHLY_REPORTS', () => getMonthlyReports(params), {
    enabled: false,
  });
};

export const useGetAdminActivityLogs = params => {
  return useQuery('GET_ACTIVITY_LOG', () => getAdminActivityLogs(params), {
    enabled: false,
  });
};

export const useGetClientData = query => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery('GET_CLIENT_DATA', () => getClientData(query), {
    enabled: false,
    onSuccess: data => {
      batch(() => {
        dispatch(
          setPatientVerification(
            data?.userProfile?.didValidateEmail &&
              data?.userProfile?.didValidatePhoneNumber,
          ),
        );
        dispatch(setPatientData(data));
        dispatch(setInbodyResultID(data._id));
      });
    },
    onError: ({ response }) => {
      if (response.status === 404) {
        dispatch(setPatientData({}));
        enqueueSnackbar(
          'No se encontró ningún paciente con los datos proporcionados',
          {
            variant: 'warning',
          },
        );
      } else {
        enqueueSnackbar('Algo salió mal, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      batch(() => {
        dispatch(
          setModalStatus({
            name: 'SEARCH_CLIENT',
            isActive: false,
          }),
        );
        dispatch(setPatientLoading(false));
      });
    },
  });
};

export const useSendInbodyResults = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(sendInbodyResults, {
    onSuccess: () => {
      enqueueSnackbar('Se enviarion con exito los resultados al paciente', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo enviar la información. Intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

export const useGetPatientsAmount = () => {
  return useQuery('GET_PATIENTS_AMOUNT', getPatientsAmount);
};

export const useGetUpcomingInbodies = () => {
  const dispatch = useDispatch();

  return useQuery('GET_UPCOMING_INBODIES', getUpcomingInbodies, {
    onSuccess: data => {
      const mapped = data.map(item => ({
        id: item._id,
        data: {
          type: {
            internal: item.location.name,
            external: 'Wellnub',
            fitpass: 'FitPass',
          }[item.type],
          name: `${item.patient.name} ${item.patient.lastName}`,
          phoneNumber: item.patient.phoneNumber,
          partner: `${item.location.name} - ${
            item?.location?.address?.neighborhood ?? 'Colonia'
          }`,
          nutritionist: item?.patient?.nutritionist?.email ?? 'N/A',
          schedule: format(
            new Date(item?.scheduleDate ?? null),
            "dd/MM/yyyy 'a las' h:mm a",
            {
              locale: es,
            },
          ),
        },
      }));
      dispatch(setUpcomingInbodyTests(mapped));
    },
  });
};

export const useGetPastInbodies = () => {
  const dispatch = useDispatch();

  return useQuery('GET_PAST_INBODIES', getPastInbodies, {
    onSuccess: data => {
      const mapped = data.map(item => ({
        id: item._id,
        data: {
          type: {
            internal: item?.location?.name,
            external: 'Wellnub',
            fitpass: 'FitPass',
          }[item.type],
          name: `${item.patient.name} ${item.patient.lastName}`,
          phoneNumber: item.patient.phoneNumber,
          partner: `${item.location.name} - ${
            item?.location?.address?.neighborhood ?? 'Colonia'
          }`,
          nutritionist: item?.patient?.nutritionist?.email ?? 'N/A',
          schedule: format(
            new Date(item?.scheduleDate ?? null),
            "dd/MM/yyyy 'a las' h:mm a",
            {
              locale: es,
            },
          ),
          status: {
            used: `Realizado ${item.inbodyResult === null ? 'N/E' : ''}`,
            cancelled: `Cancelado`,
            missedAppointment: 'Perdido',
            expired: 'Expirado',
            scheduled: 'Agendado',
            readyForSchedule: 'Listo para agendar',
            active: 'Activo',
          }[item.status],
        },
      }));
      dispatch(setTestScheduledLastTwoDays(mapped));
    },
  });
};

export const useDeleteUpcomingInbody = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(deleteUpcomningInbody, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_UPCOMING_INBODIES');
      queryClient.invalidateQueries('GET_PAST_INBODIES');
      enqueueSnackbar('Se canceló con éxito el análisis agendado', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CANCEL_UPCOMING_INBODY',
          isActive: false,
        }),
      );
    },
  });
};

export const useGetDiscountCampaings = () => {
  const dispatch = useDispatch();

  return useQuery('GET_DISCOUNT_CAMPAINGS', getDiscountCampaings, {
    onSuccess: data => {
      dispatch(setDiscountCampaings(data));
    },
  });
};

// ---- CREA UNA NUEVA CAMPAÑA PARA PROMOCIONES ----
export const useCreateDiscountCampaing = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(postDiscountCampaing, {
    onSuccess: data => {
      queryClient.invalidateQueries('GET_DISCOUNT_CAMPAINGS');
      enqueueSnackbar('Se creó la campaña promocional con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- DESHABILITA UNA CAMPAÑA DE PROMOCIONES ----
export const useDisableDiscountCampaing = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(deleteDiscountCampaing, {
    onSuccess: data => {
      queryClient.invalidateQueries('GET_DISCOUNT_CAMPAINGS');
      enqueueSnackbar('Se desactivó la campaña promocional con éxito', {
        variant: 'success',
      });
      batch(() => {
        dispatch(
          setModalStatus({
            name: 'DISABLE_DISCOUNT_CAMPAING',
            isActive: false,
          }),
        );
        dispatch(setActiveID(''));
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- TRAE LA LISTA DE MESES SIN INTERESES ----
export const useGetInterestFreeList = () => {
  const dispatch = useDispatch();

  return useQuery('GET_INTEREST_FREE', getInterestFreeList, {
    onSuccess: data => {
      dispatch(setInterestFree(data));
    },
  });
};

// ---- ACTUALIZA LA INFORMACIÓN DE UNA SECCIÓN CON MSI ----
export const usePatchInterestFree = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(patchInterestFree, {
    onSuccess: data => {
      queryClient.invalidateQueries('GET_INTEREST_FREE');
      enqueueSnackbar('Se actualizó la información con éxito', {
        variant: 'success',
      });
      dispatch(
        setModalStatus({
          name: 'SET_INTEREST_FREE',
          isActive: false,
        }),
      );
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- VALIDA MANUALMENTE A UN CLIENTE ----
export const useVerifyClient = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(validateClient, {
    onSuccess: data => {
      dispatch(
        setPatientVerification(
          data?.didValidateEmail && data?.didValidatePhoneNumber,
        ),
      );
      enqueueSnackbar('Se verificó exitosamente el usuario', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intenta más tarde.',
          {
            variant: 'error',
          },
        );
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CONFIRM_VERIFY_PATIENT',
          isActive: false,
        }),
      );
    },
  });
};

// ---- REALIZA UN CHECK IN MANUAL DE UN ANÁLISIS INBODY ----
export const useCreateCheckInInbody = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(postCreateCheckInInbody, {
    onSuccess: () => {
      enqueueSnackbar('Se realizó correctamente el check in', {
        variant: 'success',
      });
      queryClient.invalidateQueries('GET_UPCOMING_INBODIES');
      queryClient.invalidateQueries('GET_PAST_INBODIES');
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intenta más tarde.',
          {
            variant: 'error',
          },
        );
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CREATE_INBODY_CHECKIN',
          isActive: false,
        }),
      );
    },
  });
};

// ---- REALIZA LA CANCELACIÓN DE UN CHECKIN DE ANÁLISIS INBODY ----
export const useUndoCheckInInbody = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(deleteCancelCheckInInbody, {
    onSuccess: () => {
      enqueueSnackbar('Se canceló correctamente el check in', {
        variant: 'success',
      });
      queryClient.invalidateQueries('GET_UPCOMING_INBODIES');
      queryClient.invalidateQueries('GET_PAST_INBODIES');
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intenta más tarde.',
          {
            variant: 'error',
          },
        );
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CANCEL_INBODY_CHECKIN',
          isActive: false,
        }),
      );
    },
  });
};

// ---- TRAE LISTA DE NOTIFICACIONES ----
export const useGetNotifications = () => {
  const dispatch = useDispatch();

  return useQuery('GET_ADMIN_NOTIFICATIONS', getNotifications, {
    onSuccess: data => {
      const notifications = data.map(item => ({
        ...item,
        actionable: [
          NOTIFICATION_TYPES.ADMIN.NEW_NUTRITIONIST,
          NOTIFICATION_TYPES.ADMIN.COMPLETED_NUTRITIONIST,
          NOTIFICATION_TYPES.PATIENT.INBODY_PASS,
          NOTIFICATION_TYPES.PATIENT.RESCHEDULE_PASS,
          NOTIFICATION_TYPES.APPOINTMENT.RESCHEDULE,
          NOTIFICATION_TYPES.ADMIN.PARTNER_LOW_STOCK,
        ],
      }));
      dispatch(setWnNotifications(notifications));
    },
  });
};

// ---- TRAE EL CONTADOR DE NOTIFICACIONES ----
export const useGetNotificationCounter = () => {
  const dispatch = useDispatch();

  return useQuery('GET_ADMIN_NOTIFICATION_COUNTER', getNotificationCounter, {
    onSuccess: data => {
      dispatch(setWnUnreadNotifications(data.new));
    },
  });
};

// ---- MARCA COMO LEIDA UNA NOTIFICACIÒN ----
export const useMarkAsSeenNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(markAsSeenNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_ADMIN_NOTIFICATIONS');
    },
  });
};