import { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import CreateIcon from '@material-ui/icons/Create';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { useStatus } from 'hooks/common.hook';

import { setActiveClient } from 'redux/slices/client.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import { notificationTargetSelector } from 'redux/selectors/notifications.selector';

import { STATUSES } from 'utils/constants';

import { Switch, useStyles } from './PartnerRow.styles';
import { setWNTargetNotification } from '../../../redux/slices/notifications.slice';

const PartnersRow = ({ partner }) => {
  const [isFocused, setIsFocused] = useState(false);

  const target = useSelector(notificationTargetSelector);

  const elementRef = useRef(null);

  const dispatch = useDispatch();

  const { mutate, isLoading } = useStatus('GET_PARTNERS');

  const { push } = useHistory();
  const { url } = useRouteMatch();

  const classes = useStyles();

  const onEditClickHandler = id => event => {
    event.preventDefault();
    push(`${url}/${id}/edit`);
  };

  const onSwitchChangeHandler = event => {
    const currentStatus = event.target.value;
    mutate({
      id: partner._id,
      data: {
        status: {
          active: 'inactive',
          inactive: 'active',
        }[currentStatus],
      },
    });
  };

  const onAddPassesClickHandler = event => {
    event.preventDefault();
    dispatch(
      setActiveClient({
        name: partner.associatedInbody.name,
        id: partner._id,
        key: 'GET_PARTNERS',
      }),
    );
    dispatch(
      setModalStatus({
        name: 'ADD_PASSES',
        isActive: true,
      }),
    );
  };

  const onRemovePassHandler = () => {
    batch(() => {
      dispatch(
        setActiveClient({
          name: partner.associatedInbody.name,
          id: partner._id,
        }),
      );
      dispatch(
        setModalStatus({
          name: 'REMOVE_PASSES',
          isActive: true,
        }),
      );
    });
  };

  useEffect(() => {
    if (target.id === partner._id) {
      setTimeout(() => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
        setIsFocused(true);
      }, 1000);
      setTimeout(() => {
        setIsFocused(false);
        dispatch(
          setWNTargetNotification({
            id: '',
            type: '',
          }),
        );
      }, 4500);
    }
  }, [target]);

  return (
    <TableRow
      key={partner.id}
      className={`${classes.row} ${isFocused && classes.focused}`}
      ref={elementRef}
    >
      {/* --- Nombre --- */}
      <TableCell align="center">
        <Typography variant="body2">
          {partner?.associatedInbody?.name}
        </Typography>
      </TableCell>
      {/* --- Colonia --- */}
      <TableCell align="center">
        <Typography variant="body2">
          {partner?.associatedInbody?.address?.neighborhood}
        </Typography>
      </TableCell>
      {/* --- Pases --- */}
      <TableCell align="center">
        <Typography variant="body2">{partner.activePasses}</Typography>
      </TableCell>
      {/* --- Pruebas del periodo --- */}
      <TableCell align="center">
        <Typography variant="body2">
          {partner?.usedPasses?.internal} / {partner?.usedPasses?.external}
        </Typography>
      </TableCell>
      {/* --- Acciones --- */}
      <TableCell align="center">
        <Tooltip title="Editar">
          <IconButton
            className={classes.action}
            onClick={onEditClickHandler(partner?.associatedInbody?._id)}
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Agregar pases">
          <IconButton
            className={classes.action}
            onClick={onAddPassesClickHandler}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Quitar pases">
          <IconButton className={classes.action} onClick={onRemovePassHandler}>
            <RemoveCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={STATUSES[partner.status].title}>
          <Switch
            color="primary"
            disabled={isLoading}
            value={partner.status}
            checked={STATUSES[partner.status].value}
            onChange={onSwitchChangeHandler}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default PartnersRow;