import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setPatientData } from 'redux/slices/patient.slice';

import { useAddPatientPasses } from 'hooks/patient.hook';

const AddPatientPassPanel = () => {
  const { data } = useSelector(state => state.patient);

  const { mutate, isLoading, isSuccess } = useAddPatientPasses();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    mutate({
      patientId: data._id,
      quantity: 1,
    });
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'ADD_PATIENT_PASS',
        isActive: false,
      }),
    );
  };

  if (isSuccess) {
    setTimeout(() => {
      dispatch(
        setPatientData({
          ...data,
          inbodyPasses: data.inbodyPasses + 1,
        }),
      );
    }, 1000);
  }

  return (
    <Box>
      <Typography>
        ¿Estás seguro que deseas añadir un pase a este paciente?
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default AddPatientPassPanel;
