import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  id: '',
  key: '',
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setActiveClient: (state, action) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.key = action.payload.key;
    },
  },
});

export const { setActiveClient } = clientSlice.actions;

export const clientReducer = clientSlice.reducer;
