import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { isEmpty } from 'lodash';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';

import { Box, Grid } from '@material-ui/core';

import { WellnubSelectInput } from '@wellnub/web-common';

import Button from '../../Button/Button.component';

import {
  useGetMonthlyReports,
  useGetAdminActivityLogs,
} from 'hooks/admin.hook';

import {
  REPORT_TYPE_OPTIONS,
  MONTHS_OPTIONS,
  YEARS_OPTIONS,
} from 'utils/constants';

import { generateCSV } from 'utils/helpers';

const InbodyReportsForm = () => {
  const [params, setParams] = useState({});

  const { refetch: getReports, isFetching: isFetchingReports } =
    useGetMonthlyReports({
      ...params,
    });

  const { refetch: getActivityLogs, isFetching: isFetchingLogs } =
    useGetAdminActivityLogs({ ...params });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      type: '',
      month: '',
      year: '',
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmitHandler = data => {
    setParams(data);
  };

  useEffect(() => {
    if (!isEmpty(params)) {
      const type = {
        nutritionist: 'nutriologos',
        partner: 'partner',
        client: 'clientes',
        rating: 'ratings',
        activityLog: 'admins',
        returned: 'pases-regresados',
      }[params.type];
      const month = {
        '01': 'enero',
        '02': 'febrero',
        '03': 'marzo',
        '04': 'abril',
        '05': 'mayo',
        '06': 'junio',
        '07': 'julio',
        '08': 'agosto',
        '09': 'septiembre',
        10: 'octubre',
        11: 'noviembre',
        12: 'diciembre',
      }[params.month];
      if (params.type === 'activityLog') {
        getActivityLogs().then(({ data }) => {
          if (isEmpty(data)) {
            enqueueSnackbar('No hay resultados para la fecha seleccionada', {
              variant: 'warning',
            });
          } else {
            const blob = generateCSV(data);
            saveAs(blob, `reporte-${type}-${month}-${params.year}.csv`);
          }
        });
      } else {
        getReports().then(({ data }) => {
          if (isEmpty(data)) {
            enqueueSnackbar('No hay resultados para la fecha seleccionada', {
              variant: 'warning',
            });
          } else {
            const blob = generateCSV(data);
            saveAs(blob, `reporte-${type}-${month}-${params.year}.csv`);
          }
        });
      }
    }
  }, [params, getReports, enqueueSnackbar, getActivityLogs]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <WellnubSelectInput
            fullWidth
            name="type"
            variant="outlined"
            label="Reporte"
            rules={{ required: 'Este campo es obligatorio' }}
            helperText="Selecciona una opción"
            control={control}
            options={REPORT_TYPE_OPTIONS}
          />
        </Grid>
        <Grid item xs={6}>
          <WellnubSelectInput
            fullWidth
            name="month"
            variant="outlined"
            label="Mes"
            rules={{ required: 'Este campo es obligatorio' }}
            helperText="Selecciona una opción"
            control={control}
            options={MONTHS_OPTIONS}
          />
        </Grid>
        <Grid item xs={6}>
          <WellnubSelectInput
            fullWidth
            name="year"
            variant="outlined"
            label="Año"
            rules={{ required: 'Este campo es obligatorio' }}
            helperText="Selecciona una opción"
            control={control}
            options={YEARS_OPTIONS}
          />
        </Grid>
      </Grid>
      <Box mt={2} textAlign="center">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          loading={isFetchingLogs || isFetchingReports}
        >
          Crear Reporte
        </Button>
      </Box>
    </form>
  );
};

export default InbodyReportsForm;
