import { useSelector } from 'react-redux';

import AddPassesForm from '../../Forms/Passes/AddPassesForm.component';

import WithModal from '../../HOC/WithModal/WithModal.component';
import DeleteScheduleOpenTime from '../Panels/DeleteScheduleOpenTime/DeleteScheduleOpenTime.component';
import DeleteScheduleCloseTime from '../Panels/DeleteScheduleCloseTime/DeleteScheduleCloseTime.component';
import SearchClientPanel from '../Panels/SearchClient/SearchClientPanel.component';
import CancelUpcomingInbodyPanel from '../Panels/CancelUpcomingInbody/CancelUpcomingInbody';
import AddPatientPassPanel from '../Panels/AddPatientPass/AddPatientPass.component';
import RemovePartnerPassPanel from '../Panels/RemovePartnerPasses/RemovePartnerPassPanel.component';
import AddPartnerAggregator from '../Panels/AddPartnerAggregator/AddPartnerAggregator.component';
import DisableDiscountCampaingPanel from '../Panels/DisableDiscountCampaing/DisableDiscountCampaing.component';
import SetInterestFreePanel from '../Panels/SetInterestFree/SetInterestFree.component';
import ConfirmInterestFreeMonthPanel from '../Panels/ConfirmInterestFreeMonth/ConfirmInterestFreeMonth.component';
import ConfirmVerifyPatientPanel from '../Panels/ConfirmVerifyPatient/ConfirmVerifyPatientPanel.component';
import CreateInbodyCheckinPanel from '../Panels/CreateInbodyCheckin/CreateInbodyCheckinPanel.component';
import UndoInbodyCheckinPanel from '../Panels/UndoInbodyCheckin/UndoInbodyCheckinPanel.component';
import DeletePatientPanel from '../Panels/DeletePatient/DeletePatientPanel.component';
import InbodyResultViewerPanel from '../Panels/InbodyResultViewer/InbodyResultViewerPanel.component';
import RemoveNutritionistPasses from '../Panels/RemoveNutritionistPasses/RemoveNutritionistPassesPanel.component';
import ToggleAgendaVirtualPanel from '../Panels/ToggleAgendaVirtual/ToggleAgendaVirtualPanel.component';

const ModalManager = () => {
  const { name, isActive } = useSelector(state => state.modal);
  const { currentAggregator } = useSelector(state => state.partner);

  switch (name) {
    case 'ADD_PASSES':
      return (
        <WithModal
          open={isActive}
          name="ADD_PASSES"
          title="Agregar pases"
          BodyComponent={<AddPassesForm />}
        />
      );
    case 'REMOVE_PASSES':
      return (
        <WithModal
          open={isActive}
          name="REMOVE_PASSES"
          title="Quitar Pases"
          BodyComponent={<RemovePartnerPassPanel />}
        />
      );
    case 'DELETE_SCHEDULE_OPEN_TIME':
      return (
        <WithModal
          open={isActive}
          name="DELETE_SCHEDULE_OPEN_TIME"
          title="Borrar entrada"
          BodyComponent={<DeleteScheduleOpenTime />}
        />
      );
    case 'DELETE_SCHEDULE_CLOSE_TIME':
      return (
        <WithModal
          open={isActive}
          name="DELETE_SCHEDULE_CLOSE_TIME"
          title="Borrar entrada"
          BodyComponent={<DeleteScheduleCloseTime />}
        />
      );
    case 'SEARCH_CLIENT':
      return (
        <WithModal
          open={isActive}
          name="SEARCH_CLIENT"
          title="Buscar paciente"
          BodyComponent={<SearchClientPanel />}
        />
      );
    case 'CANCEL_UPCOMING_INBODY':
      return (
        <WithModal
          open={isActive}
          name="CANCEL_UPCOMING_INBODY"
          title="Cancelar escaneo"
          BodyComponent={<CancelUpcomingInbodyPanel />}
        />
      );
    case 'ADD_PATIENT_PASS':
      return (
        <WithModal
          open={isActive}
          name="ADD_PATIENT_PASS"
          title="Agregar Pases"
          BodyComponent={<AddPatientPassPanel />}
        />
      );
    case 'ADD_PARTNER_AGGREGATOR_CONFIRMATION':
      return (
        <WithModal
          open={isActive}
          name="ADD_PARTNER_AGGREGATOR_CONFIRMATION"
          title={`${
            currentAggregator.enabled ? 'Desactivar' : 'Activar'
          } Agregador`}
          BodyComponent={<AddPartnerAggregator />}
        />
      );
    case 'DISABLE_DISCOUNT_CAMPAING':
      return (
        <WithModal
          open={isActive}
          name="DISABLE_DISCOUNT_CAMPAING"
          title="Confirmar acción"
          BodyComponent={<DisableDiscountCampaingPanel />}
        />
      );
    case 'SET_INTEREST_FREE':
      return (
        <WithModal
          open={isActive}
          name="SET_INTEREST_FREE"
          title="Confirmar acción"
          BodyComponent={<SetInterestFreePanel />}
        />
      );
    case 'CONFIRM_SET_INTEREST_FREE_MONTH':
      return (
        <WithModal
          open={isActive}
          name="CONFIRM_SET_INTEREST_FREE_MONTH"
          title="Confirmar acción"
          BodyComponent={<ConfirmInterestFreeMonthPanel />}
        />
      );
    case 'CONFIRM_VERIFY_PATIENT':
      return (
        <WithModal
          open={isActive}
          name="CONFIRM_VERIFY_PATIENT"
          title="Verificar paciente"
          BodyComponent={<ConfirmVerifyPatientPanel />}
        />
      );
    case 'CREATE_INBODY_CHECKIN':
      return (
        <WithModal
          open={isActive}
          name="CREATE_INBODY_CHECKIN"
          title="Confirmar Check In"
          BodyComponent={<CreateInbodyCheckinPanel />}
        />
      );
    case 'UNDO_INBODY_CHECKIN':
      return (
        <WithModal
          open={isActive}
          name="UNDO_INBODY_CHECKIN"
          title="Revertir Check In"
          BodyComponent={<UndoInbodyCheckinPanel />}
        />
      );
    case 'DELETE_PATIENT':
      return (
        <WithModal
          open={isActive}
          name="DELETE_PATIENT"
          title="Eliminar paciente"
          BodyComponent={<DeletePatientPanel />}
        />
      );
    case 'INBODY_RESULT_VIEWER':
      return (
        <WithModal
          open={isActive}
          name="INBODY_RESULT_VIEWER"
          title="Subir resultados"
          BodyComponent={<InbodyResultViewerPanel />}
        />
      );
    case 'REMOVE_NUTRITIONIST_PASSES':
      return (
        <WithModal
          open={isActive}
          name="REMOVE_NUTRITIONIST_PASSES"
          title="Retirar pases"
          BodyComponent={<RemoveNutritionistPasses />}
        />
      );
    case 'TOGGLE_AGENDA_VIRTUAL':
      return (
        <WithModal
          open={isActive}
          name="TOGGLE_AGENDA_VIRTUAL"
          title="Agenda Virtual"
          BodyComponent={<ToggleAgendaVirtualPanel />}
        />
      );
    default:
      return null;
  }
};

export default ModalManager;
