import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import UpcommingInbodyRow from '../../Rows/UpcommingInbody/UpcommingInbodyRow.component';

const useStyles = makeStyles(theme => ({
  cellHeader: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    backgroundColor: 'black',
    color: 'white',
  },
  action: {
    padding: theme.spacing(1),
    color: theme.palette.action.main,
  },
  actionsCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const UpcommingInbodiesTable = ({ upcommingInbodies = [] }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cellHeader}>
              Tipo de cliente
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Nombre
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Celular
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Partner - Colonia
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Nutriólogo
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Horario
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {upcommingInbodies.map(item => (
            <UpcommingInbodyRow key={nanoid()} inbody={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UpcommingInbodiesTable;