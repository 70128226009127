import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  file: null,
  patientID: '',
};

const inbodyResultFile = createSlice({
  name: 'inbodyResultFile',
  initialState,
  reducers: {
    setInbodyResultFile: (state, action) => {
      state.file = action.payload;
    },
    setInbodyResultID: (state, action) => {
      state.patientID = action.payload;
    },
  },
});

export const { setInbodyResultFile, setInbodyResultID } =
  inbodyResultFile.actions;

export const InbodyResultReducer = inbodyResultFile.reducer;
