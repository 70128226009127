import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  tab: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.primary.main,
  },
}));

const DiscountsPageNavigation = () => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const classes = useStyles();

  const tabValueHandler = () => {
    if (pathname.includes('promos')) return 'promos';
    if (pathname.includes('interest-free')) return 'interest-free';
    return 'promos';
  };

  return (
    <Tabs
      value={tabValueHandler()}
      indicatorColor="primary"
      variant="fullWidth"
    >
      <Tab
        value="promos"
        component={Link}
        label="Códigos promocionales"
        to={`${url}/promos`}
        classes={{ wrapper: classes.tab }}
      />
      <Tab
        value="interest-free"
        component={Link}
        label="Meses sin intereses"
        to={`${url}/interest-free`}
        classes={{ wrapper: classes.tab }}
      />
    </Tabs>
  );
};

export default DiscountsPageNavigation;
