export const overrides = {
  MuiCssBaseline: {
    '@global': {
      '*, *::before, *::after': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
      html: {
        scrollBehavior: 'smooth',
        WebkitFontSmoothing: 'auto',
      },
      body: {
        wordSpacing: 1,
      },
      ul: {
        listStyle: 'none',
      },
      a: {
        textDecoration: 'none',
      },
    },
  },
  MuiButton: {
    root: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: 13,
    },
  },
};
