import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { Box, CircularProgress, makeStyles } from '@material-ui/core';

import {
  SignInPage,
  CoachPage,
  CoachesPage,
  PartnersPage,
  NewPartnerPage,
  EditPartnerPage,
  NutritionistPage,
  NutritionistsPage,
  InbodyReportsPage,
  ClientsPage,
  UpcomingInbodiesPage,
  DiscountsPage,
} from 'pages';

import { useGetNotificationCounter } from 'hooks/admin.hook';

import { Header, Footer, ModalManager } from 'components';

import { setAuthentication } from 'redux/slices/authentication.slice';

import { withProtected, withRedirect } from 'utils/authentication';

const useStyles = makeStyles(() => ({
  app: {
    minHeight: props =>
      `calc(100vh - ${props.footerHeight}px - ${props.headerHeight}px)`,
  },
}));

const App = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const { isLoading } = useGetNotificationCounter();

  const dispatch = useDispatch();

  const classes = useStyles({ headerHeight, footerHeight });

  const isAuthenticated = JSON.parse(localStorage.getItem('authentication'));

  const measuredHeaderRef = useCallback(node => {
    if (node !== null) {
      setHeaderHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const measuredFooterRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.getBoundingClientRect().height);
    }
  }, []);

  if (isAuthenticated) {
    dispatch(setAuthentication(true));
  }

  if (isLoading) {
    return (
      <Box py={4} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ModalManager />
      <Header ref={measuredHeaderRef} />
      <Box component="main" className={classes.app}>
        <Switch>
          <Route exact path="/" component={withRedirect(SignInPage)} />
          <Route
            exact
            path="/partners"
            component={withProtected(PartnersPage)}
          />
          <Route exact path="/clients" component={withProtected(ClientsPage)} />
          <Route
            exact
            path="/partners/:id/edit"
            component={withProtected(EditPartnerPage)}
          />
          <Route
            exact
            path="/partners/new"
            component={withProtected(NewPartnerPage)}
          />
          <Route
            exact
            path="/nutritionists"
            component={withProtected(NutritionistsPage)}
          />
          <Route
            exact
            path="/nutritionists/:id"
            component={withProtected(NutritionistPage)}
          />
          <Route
            exact
            path="/coaches/:id"
            component={withProtected(CoachPage)}
          />
          <Route exact path="/coaches" component={withProtected(CoachesPage)} />
          <Route
            exact
            path="/inbody-reports"
            component={withProtected(InbodyReportsPage)}
          />
          <Route
            exact
            path="/upcoming-inbodies"
            component={withProtected(UpcomingInbodiesPage)}
          />
          <Route path="/discounts" component={withProtected(DiscountsPage)} />
        </Switch>
      </Box>
      <Footer ref={measuredFooterRef} />
    </>
  );
};

export default App;
