import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
  WellnubTextInput,
} from '@wellnub/web-common';

import { useGetClientData } from 'hooks/admin.hook';

import { setPatientLoading } from 'redux/slices/patient.slice';

const SearchClientPanel = () => {
  const [searchType, setSearchType] = useState('email');
  const [query, setQuery] = useState('');

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      phoneNumber: '',
      countryCode: '52',
    },
  });

  const { refetch, isLoading } = useGetClientData(query);

  const dispatch = useDispatch();

  const onSubmitHandler = data => {
    setQuery(data[searchType]);
    dispatch(setPatientLoading(true));
    setTimeout(() => {
      refetch();
    }, 100);
  };

  const onChangeSearchTypeHandler = event => {
    setSearchType(event.target.value);
  };

  return (
    <Box>
      <Typography>
        Escribe el {{ email: 'e-mail', phoneNumber: 'teléfono' }[searchType]}{' '}
        del paciente que deseas consultar
      </Typography>
      <FormControl fullWidth style={{ marginBottom: 8 }}>
        <RadioGroup
          row
          style={{ justifyContent: 'space-evenly' }}
          value={searchType}
          onChange={onChangeSearchTypeHandler}
        >
          <FormControlLabel
            value="email"
            control={<Radio color="primary" />}
            label="Email"
          />
          <FormControlLabel
            value="phoneNumber"
            control={<Radio color="primary" />}
            label="Teléfono"
          />
        </RadioGroup>
      </FormControl>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        style={{ textAlign: 'center' }}
      >
        {searchType === 'phoneNumber' && (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <WellnubCountryCodeInput
                label="Región"
                control={control}
                name="countryCode"
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <WellnubNumberInput
                fullWidth
                variant="outlined"
                label="Teléfono"
                name="phoneNumber"
                control={control}
                format="## #### ####"
              />
            </Grid>
          </Grid>
          // <Controller
          //   control={control}
          //   name="phoneNumber"
          //   rules={{
          //     validate: value => validatePhoneNumber(value),
          //   }}
          //   render={({ field: { value, onChange }, fieldState: { error } }) => (
          //     <MUIPhoneInput
          //       value={value}
          //       fullWidth
          //       variant="outlined"
          //       error={!!error}
          //       label="Celular"
          //       defaultCountry="mx"
          //       onChange={onChange}
          //       helperText={error?.message ?? ''}
          //       onlyCountries={['mx', 'us']}
          //     />
          //   )}
          // />
        )}
        {searchType === 'email' && (
          <WellnubTextInput
            fullWidth
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            control={control}
          />
        )}
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          Buscar
        </Button>
      </form>
    </Box>
  );
};

export default SearchClientPanel;
