import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';

import { useDeleteUpcomingInbody } from 'hooks/admin.hook';

import { setModalStatus } from 'redux/slices/modal.slice';

const CancelUpcomingInbodyPanel = () => {
  const { idToCancel } = useSelector(state => state.upcomingEvents);

  const { mutate, isLoading } = useDeleteUpcomingInbody();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    mutate(idToCancel);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CANCEL_UPCOMING_INBODY',
        isActive: false,
      }),
    );
  };

  return (
    <Box textAlign="center">
      <Typography>
        ¿Estás seguro que deseas cancelar el análisis agendado?
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        mt={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default CancelUpcomingInbodyPanel;
