import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idToCancel: '',
  inbodyTests: [],
  testScheduledLastTwoDays: [],
};

const upcomingEvents = createSlice({
  name: 'upcomingEvents',
  initialState,
  reducers: {
    setUpcomingInbodyTests: (state, action) => {
      state.inbodyTests = action.payload;
    },
    setTestScheduledLastTwoDays: (state, action) => {
      state.testScheduledLastTwoDays = action.payload;
    },
    setIdToCancel: (state, action) => {
      state.idToCancel = action.payload;
    },
  },
});

export const {
  setUpcomingInbodyTests,
  setTestScheduledLastTwoDays,
  setIdToCancel,
} = upcomingEvents.actions;

export const UpcomingEventsReducer = upcomingEvents.reducer;
