import { wellnub } from '../../wellnub.api';

export const getNotifications = async () => {
  const { data } = await wellnub.get('/v1/admin/persistentNotification');
  return data;
};

export const getNotificationCounter = async () => {
  const { data } = await wellnub.get(
    '/v1/admin/persistentNotification/new/count',
  );
  return data;
};

export const markAsSeenNotification = async id => {
  const { data } = await wellnub.patch(
    `/v1/admin/persistentNotification/markAsSeen/${id}`,
  );
  return data;
};
