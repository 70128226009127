import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

const nutritionistSlice = createSlice({
  name: 'nutritionist',
  initialState,
  reducers: {
    setNutritionistData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setNutritionistData } = nutritionistSlice.actions;
export const NutritionistReducer = nutritionistSlice.reducer;
