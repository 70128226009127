import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  isVerificated: false,
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatientData: (state, action) => {
      state.data = action.payload;
    },
    setPatientLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPatientVerification: (state, action) => {
      state.isVerificated = action.payload;
    },
  },
});

export const { setPatientData, setPatientLoading, setPatientVerification } =
  patientSlice.actions;

export const PatientReducer = patientSlice.reducer;
