import { batch, useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setActiveID } from 'redux/slices/discounts.slice';
import { useDisableDiscountCampaing } from 'hooks/admin.hook';

const DisableDiscountCampaingPanel = () => {
  const { activeID } = useSelector(state => state.discounts);

  const { mutate, isLoading } = useDisableDiscountCampaing();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    mutate(activeID);
  };

  const onCancelHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'DISABLE_DISCOUNT_CAMPAING',
          isActive: false,
        }),
      );
      dispatch(setActiveID(''));
    });
  };

  return (
    <Box>
      <Typography>¿Estás seguro que deseas desactivar esta campaña?</Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DisableDiscountCampaingPanel;
