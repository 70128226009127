import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import {
  setCurrentAggregator,
  setPartnerAggregator,
} from 'redux/slices/partner.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

const AddPartnerAggregator = () => {
  const { currentAggregator } = useSelector(state => state.partner);

  const dispatch = useDispatch();

  const onAcceptClickHandler = () => {
    batch(() => {
      dispatch(
        setPartnerAggregator({
          ...currentAggregator,
          enabled: !currentAggregator.enabled,
        }),
      );
      dispatch(
        setModalStatus({
          name: 'ADD_PARTNER_AGGREGATOR_CONFIRMATION',
          isActive: false,
        }),
      );
    });
  };

  const onCancelClickHandler = () => {
    dispatch(
      setModalStatus({
        name: 'ADD_PARTNER_AGGREGATOR_CONFIRMATION',
        isActive: false,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentAggregator({}));
    };
  }, [dispatch]);

  return (
    <Box>
      <Typography>
        ¿Estás seguro de que deseas{' '}
        {currentAggregator.enabled ? 'desactivar' : 'activar'} esta opción?
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptClickHandler}
        >
          Aceptar
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancelClickHandler}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default AddPartnerAggregator;
