export const NOTIFICATION_TYPES = {
  APPOINTMENT: {
    RESCHEDULE: 'rescheduledAppointment',
    CANCEL: 'cancelledAppointmemnt',
  },
  INBODY: {
    MISSED: 'patientMissedInbody',
    NEW: 'newPatientInBody',
  },
  PATIENT: {
    INBODY_PASS: 'patientUsedPass',
    RESCHEDULE_PASS: 'patientRescheduledPass',
  },
  GENERAL: {
    ADD_PASSES: 'addedPasses',
  },
  REQUESTS: {
    LINK: 'newLinkRequest',
  },
  ADMIN: {
    PARTNER_LOW_STOCK: 'partnerLowStock',
    NEW_NUTRITIONIST: 'newNutritionist',
    ACTIVATED_NUTRITIONIST: 'activatedNutritionist',
    COMPLETED_NUTRITIONIST: 'completedNutritionist',
  },
};

export const sideMenuLinks = [
  {
    url: '/partners',
    name: 'Partners',
  },
  {
    url: '/clients',
    name: 'Pacientes',
  },
  {
    url: '/nutritionists',
    name: 'Nutriologos',
  },
  {
    url: '/coaches',
    name: 'Coaches',
  },
  {
    url: '/inbody-reports',
    name: 'Reportes',
  },
  {
    url: '/upcoming-inbodies',
    name: 'Próximos escaneos',
  },
  {
    url: '/discounts',
    name: 'Promociones',
  },
];

export const STATUSES = {
  active: {
    title: 'Activo',
    value: true,
  },
  inactive: {
    title: 'Inactivo',
    value: false,
  },
  'pending validation': {
    title: 'Pendiente de activar',
    value: false,
  },
  pendingValidation: {
    title: 'Pendiente de activar',
    value: false,
  },
};

export const YEARS = [
  { name: '2021', value: 2021 },
  { name: '2022', value: 2022 },
  { name: '2023', value: 2023 },
  { name: '2024', value: 2024 },
  { name: '2025', value: 2025 },
  { name: '2026', value: 2026 },
  { name: '2027', value: 2027 },
  { name: '2028', value: 2028 },
  { name: '2029', value: 2029 },
  { name: '2030', value: 2030 },
];

export const MONTHS = [
  { name: 'Enero', value: 1 },
  { name: 'Febrero', value: 2 },
  { name: 'Marzo', value: 3 },
  { name: 'Abril', value: 4 },
  { name: 'Mayo', value: 5 },
  { name: 'Junio', value: 6 },
  { name: 'Julio', value: 7 },
  { name: 'Agosto', value: 8 },
  { name: 'Septiembre', value: 9 },
  { name: 'Octubre', value: 10 },
  { name: 'Noviembre', value: 11 },
  { name: 'Diciembre', value: 12 },
];

export const YEARS_OPTIONS = [
  { id: 'year-1', label: '2020', value: 2020 },
  { id: 'year-2', label: '2021', value: 2021 },
  { id: 'year-3', label: '2022', value: 2022 },
  { id: 'year-4', label: '2023', value: 2023 },
  { id: 'year-5', label: '2024', value: 2024 },
];

export const MONTHS_OPTIONS = [
  { id: 'month-1', label: 'Enero', value: '01' },
  { id: 'month-2', label: 'Febrero', value: '02' },
  { id: 'month-3', label: 'Marzo', value: '03' },
  { id: 'month-4', label: 'Abril', value: '04' },
  { id: 'month-5', label: 'Mayo', value: '05' },
  { id: 'month-6', label: 'Junio', value: '06' },
  { id: 'month-7', label: 'Julio', value: '07' },
  { id: 'month-8', label: 'Agosto', value: '08' },
  { id: 'month-9', label: 'Septiembre', value: '09' },
  { id: 'month-10', label: 'Octubre', value: '10' },
  { id: 'month-11', label: 'Noviembre', value: '11' },
  { id: 'month-12', label: 'Diciembre', value: '12' },
];

export const REPORT_TYPE_OPTIONS = [
  { id: 'type-1', label: 'Uso de InBodys nutriólogos', value: 'nutritionist' },
  { id: 'type-2', label: 'Uso de InBodys partners', value: 'partner' },
  { id: 'type-3', label: 'Reporte ratings partners', value: 'rating' },
  { id: 'type-4', label: 'Actividad admins', value: 'activityLog' },
  {
    id: 'type-5',
    label: 'Uso de Inbodys clientes independientes',
    value: 'client',
  },
  {
    id: 'type-6',
    label: 'Pases devueltos a nutriólogos',
    value: 'returned',
  },
];

export const DAYS = {
  1: 'Lu',
  2: 'Ma',
  3: 'Mi',
  4: 'Ju',
  5: 'Vi',
  6: 'Sa',
  0: 'Do',
};

export const WEEK_OPTIONS = [
  { id: 'day-1', label: 'Lu', value: 1 },
  { id: 'day-2', label: 'Ma', value: 2 },
  { id: 'day-3', label: 'Mi', value: 3 },
  { id: 'day-4', label: 'Ju', value: 4 },
  { id: 'day-5', label: 'Vi', value: 5 },
  { id: 'day-6', label: 'Sa', value: 6 },
  { id: 'day-0', label: 'Do', value: 0 },
];

export const PARTNER_AGGREGATORS = ['checkInExternalCompany'];
