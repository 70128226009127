import {
  makeStyles,
  withStyles,
  Switch as StyledSwitch,
} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  row: {
    transition: 'background-color 250ms linear',
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  action: {
    color: theme.palette.action.main,
  },
  focused: {
    backgroundColor: '#b6dbb6 !important',
  },
}));

export const Switch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.secondary.dark,
    '&$checked': {
      color: theme.palette.action.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.action.light,
    },
    '&$disabled': {
      color: theme.palette.secondary.main,
    },
  },
  checked: {},
  track: {},
  disabled: {},
}))(StyledSwitch);