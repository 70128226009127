import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useStyles } from './PartnerTable.styles';

import PartnerRow from '../../Rows/Partner/PartnerRow.component';

const PartnersTable = ({ partners }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cellHeader}>
              Nombre
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Colonia
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Pases disponibles
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Pruebas en periodo <br />
              Partner/Wellnub
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partners.map(partner => (
            <PartnerRow key={partner._id} partner={partner} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PartnersTable;
