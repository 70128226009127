import { createSelector } from '@reduxjs/toolkit';
import { notificationsAdapter } from '../slices/notifications.slice';

export const newNutritionistNotificationsSelector = createSelector(
  [state => state.wnNotifications.unread],
  unreadNotifications => unreadNotifications,
);

export const { selectAll: notificationsSelector } =
  notificationsAdapter.getSelectors(
    state => state.wnNotifications.notifications,
  );

export const notificationTargetSelector = createSelector(
  [state => state.wnNotifications.target],
  target => target,
);