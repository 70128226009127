import { useMutation, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import { patchUserStatus } from 'network/services/common.service';

// ---- ACTUALIZAR ESTADO DEL USUARIO ----
export const useStatus = key => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(patchUserStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
      enqueueSnackbar('Se actualizó con éxito el status del usuario', {
        variant: 'success',
      });
    },
  });
};
