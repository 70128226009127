import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const notificationsAdapter = createEntityAdapter({
  selectId: notification => notification._id,
  sortComparer: (a, b) =>
    new Date(a.date).getDate() - new Date(b.date).getDate(),
});

const initialState = {
  unread: 0,
  notifications: notificationsAdapter.getInitialState(),
  target: {
    type: '',
    id: '',
  },
};

const notificationsSlice = createSlice({
  name: 'wnNotifications',
  initialState,
  reducers: {
    setWnNotifications: (state, action) => {
      notificationsAdapter.setAll(state.notifications, action.payload);
    },
    setWnUnreadNotifications: (state, action) => {
      state.unread = action.payload;
    },
    setWNTargetNotification: (state, action) => {
      state.target.type = action.payload.type;
      state.target.id = action.payload.id;
    },
  },
});

export const {
  setWnNotifications,
  setWnUnreadNotifications,
  setWNTargetNotification,
} = notificationsSlice.actions;

export const WNNotificationsReducer = notificationsSlice.reducer;