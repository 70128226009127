import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useUndoCheckInInbody } from 'hooks/admin.hook';

const UndoInbodyCheckinPanel = () => {
  const { idToCancel } = useSelector(state => state.upcomingEvents);

  const { mutate: undoCheckin, isLoading } = useUndoCheckInInbody();

  const dispatch = useDispatch();

  const onConfirmHandler = () => {
    undoCheckin(idToCancel);
  };

  const onCancerHandler = () => {
    dispatch(
      setModalStatus({
        name: 'UNDO_INBODY_CHECKIN',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Deseas deshacer el check-in de este paciente?</Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirmHandler}
          disabled={isLoading}
        >
          Confirmar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancerHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default UndoInbodyCheckinPanel;
