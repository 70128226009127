import { forwardRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  AppBar,
  Box,
  Button,
  CardMedia,
  IconButton,
  Toolbar,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import mainLogo from 'assets/images/logo-small.png';

import { setAuthentication } from 'redux/slices/authentication.slice';

import SideMenu from '../SideMenu/SideMenu.component';
import NotificationIndicator from '../NotificationIndicator/NotificationIndicator.component';

import { useStyles } from './Header.styles';

const Header = (_, ref) => {
  const [sideMenuStatus, setSideMenuStatus] = useState(false);

  const { isAuthenticated } = useSelector(state => state.authentication);

  const dispatch = useDispatch();

  const { replace } = useHistory();

  const classes = useStyles();

  const sideMenuStatusHandler = status => () => {
    setSideMenuStatus(status);
  };

  const onSignOutHandler = () => {
    localStorage.removeItem('authentication');
    localStorage.removeItem('accessToken');
    dispatch(setAuthentication(false));
    replace('/');
  };

  return (
    <AppBar position="static" ref={ref}>
      <SideMenu
        isOpen={sideMenuStatus}
        closeHandler={sideMenuStatusHandler(false)}
      />
      <Toolbar>
        {isAuthenticated && (
          <IconButton
            edge="start"
            color="secondary"
            onClick={sideMenuStatusHandler(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <CardMedia
            component="img"
            image={mainLogo}
            className={classes.logo}
          />
          {isAuthenticated && (
            <Box display="flex" alignItems="center">
              <NotificationIndicator />
              <Button variant="text" color="inherit" onClick={onSignOutHandler}>
                Salir
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default forwardRef(Header);
