import { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useForm } from 'react-hook-form';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { isEmpty } from 'lodash';

import { Box, Grid, Typography } from '@material-ui/core';

import {
  WellnubTable,
  WellnubTimeInput,
  WellnubDateInput,
} from '@wellnub/web-common';

import Button from '../../Button/Button.component';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setScheduleTimeId } from 'redux/slices/partner.slice';

import { usePartnerData } from 'hooks/partners.hook';

const HEADERS = ['Acciones', 'Horario', 'Día'];

const DayOffScheduleTable = () => {
  const [data, setData] = useState([]);

  const { data: partner } = useSelector(state => state.partner);

  const { mutateAsync, isLoading } = usePartnerData();

  const { getValues, control, reset } = useForm({
    defaultValues: {
      startTime: new Date(),
      endTime: new Date(),
      date: new Date(),
    },
  });

  const dispatch = useDispatch();

  const ACTIONS = [
    {
      type: 'DELETE',
      title: 'Eliminar',
      handler: id => {
        batch(() => {
          dispatch(
            setModalStatus({
              isActive: true,
              name: 'DELETE_SCHEDULE_CLOSE_TIME',
            }),
          );
        });
        dispatch(setScheduleTimeId(id));
      },
    },
  ];

  const onSaveHandler = () => {
    const { date, startTime, endTime } = getValues();
    const day = format(date, 'd');
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();

    const start = new Date(
      year,
      month,
      day,
      startTime.getHours(),
      startTime.getMinutes(),
    );
    const end = new Date(
      year,
      month,
      day,
      endTime.getHours(),
      endTime.getMinutes(),
    );
    mutateAsync({
      id: partner._id,
      data: {
        closingDates: [
          ...partner.closingDates.map(date => date.data),
          {
            startDate: start,
            endDate: end,
          },
        ],
      },
    })
      .then(() => {
        reset();
      })
      .catch(e => {});
  };

  useEffect(() => {
    if (!isEmpty(partner.closingDates)) {
      const dates = partner.closingDates.map(date => {
        const startTime = new Date(date.data.startDate);
        const endTime = new Date(date.data.endDate);
        return {
          ...date,
          data: {
            schedule: `${format(startTime, 'HH:mm')} - ${format(
              endTime,
              'HH:mm',
            )}`,
            date: `${format(startTime, 'PP', { locale: es })}`,
          },
        };
      });
      setData(dates);
    } else {
      setData([]);
    }
  }, [partner.closingDates]);

  return (
    <Box>
      <WellnubTable headers={HEADERS} actions={ACTIONS} rows={data} />
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Horario Inicio</Typography>
          <WellnubTimeInput
            fullWidth
            name="startTime"
            control={control}
            inputVariant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Horario Fin</Typography>
          <WellnubTimeInput
            fullWidth
            name="endTime"
            control={control}
            inputVariant="outlined"
          />
        </Grid>
        <Grid item xs={6} sm={3} style={{ textAlign: 'center' }}>
          <Typography variant="h6">Fecha</Typography>
          <WellnubDateInput
            fullWidth
            name="date"
            minDate={new Date()}
            maxDate={new Date(2025, 11)}
            control={control}
            inputVariant="outlined"
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
            loading={isLoading}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DayOffScheduleTable;
