import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import {
  useAddPartnerAggregator,
  usePartnerData,
  usePartnerRegister,
  useRemovePartnerAggregator,
} from 'hooks/partners.hook';
import { setModalStatus } from 'redux/slices/modal.slice';
import { setCurrentAggregator } from 'redux/slices/partner.slice';

import Button from '../../Button/Button.component';
import WorkdayScheduleTable from '../../Tables/WorkdayScheduleTable/WorkdayScheduleTable.component';
import DayOffScheduleTable from '../../Tables/DayOffScheduleTable/DayOffScheduleTable.component';

import { useStyles } from './PartnerForm.styles';

const PartnerForm = ({ editable, data }) => {
  const [hasError, setHasError] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [passwordConfirmStatus, setPasswordConfirmStatus] = useState(false);

  const { aggregators, data: partnerData } = useSelector(
    state => state.partner,
  );

  const dispatch = useDispatch();

  const { mutate: createPartner, isLoading: isCreateLoading } =
    usePartnerRegister();

  const { mutate: updatePartner, isLoading: isUpdateLoading } =
    usePartnerData();

  const { mutate: addPartnerAggregator } = useAddPartnerAggregator();

  const { mutate: removePartnerAggregator } = useRemovePartnerAggregator();

  const { control, handleSubmit, trigger } = useForm({
    defaultValues: editable ? data : {},
  });

  const classes = useStyles();

  const onAgregatorSwitchChangeHandler = aggregator => event => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'ADD_PARTNER_AGGREGATOR_CONFIRMATION',
          isActive: true,
        }),
      );
      dispatch(setCurrentAggregator(aggregator));
    });
  };

  const onSubmitHandler = inputs => {
    const { inbodyScheduleTimes, closingDates, ...restInputs } = inputs;
    const { enabled, disabled } = aggregators.reduce(
      (accumulator, current) => {
        if (current.enabled) accumulator.enabled.push(current.name);
        else accumulator.disabled.push(current.name);
        return accumulator;
      },
      { enabled: [], disabled: [] },
    );
    if (editable) {
      if (!isEmpty(enabled)) {
        addPartnerAggregator({
          id: data.user._id,
          data: {
            permission: enabled[0],
          },
        });
      }
      if (!isEmpty(disabled)) {
        removePartnerAggregator({
          id: data.user._id,
          data: {
            permission: disabled[0],
          },
        });
      }
      updatePartner({
        id: data._id,
        data: { ...restInputs },
      });
    } else {
      createPartner(inputs);
    }
  };

  useEffect(() => {
    setHasError(!isEmpty(partnerData?.inbodyScheduleTimes));
  }, [partnerData]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Typography variant="h4" className={classes.title}>
        Datos generales para clientes externos
      </Typography>

      <Grid container spacing={4}>
        {/* --- Nombre --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="name"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Nombre"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error ? error.message : 'Escribe el nombre de tu gym/estudio'
                }
              />
            )}
          />
        </Grid>
        {/* --- Email --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="email"
                label="E-mail"
                value={value}
                error={!!error}
                disabled={editable}
                onChange={onChange}
                helperText={error ? error.message : 'user@example.com'}
              />
            )}
          />
        </Grid>
        {/* --- Tel. sucursal --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="phoneNumber"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Tel. sucursal"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Teléfono fijo de contacto'}
              />
            )}
          />
        </Grid>

        {/* --- Tel. sucursal --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="website"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Sitio Web"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error ? error.message : 'Sitio web o página en redes sociales'
                }
              />
            )}
          />
        </Grid>

        {/* --- Tel. sucursal --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="address.street"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Dirección"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error
                    ? error.message
                    : 'Calle, número externo de casa, edificio, número de departamento / interior'
                }
              />
            )}
          />
        </Grid>

        {/* --- Tel. sucursal --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="address.neighborhood"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Colonia"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error ? error.message : 'Sitio web o página en redes sociales'
                }
              />
            )}
          />
        </Grid>

        {/* --- Ciudad --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="address.city"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Ciudad"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Ciudad'}
              />
            )}
          />
        </Grid>

        {/* --- Estado --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="address.state"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Estado"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Estado'}
              />
            )}
          />
        </Grid>

        {/* --- Código Postal --- */}
        <Grid item md={6} sm={12}>
          <Controller
            name="address.zipCode"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Código Postal"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'Código Postal'}
              />
            )}
          />
        </Grid>
        {/* --- Enlace Google Maps--- */}
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            name="address.mapUrl"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Link Google Maps"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={
                  error
                    ? error.message
                    : 'Introduce el enlace de tu ubicación en Google Maps'
                }
              />
            )}
          />
        </Grid>
        {/* --- Indicaciones --- */}
        <Grid item md={12} sm={12}>
          <Controller
            name="indications"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                multiline
                rows={4}
                type="text"
                label="Otras indicaciones"
                value={value}
                error={!!error}
                variant="outlined"
                onChange={onChange}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <Typography variant="h4" className={classes.title}>
        Inbody asignado
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12}>
          <Controller
            name="inbodyNumber"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type="text"
                label="Número de serie"
                value={value}
                error={!!error}
                onChange={onChange}
                helperText={error ? error.message : 'xxx-xxx-xxx'}
              />
            )}
          />
        </Grid>
      </Grid>

      {editable && (
        <>
          <br />
          <br />
          <Typography variant="h4" className={classes.title}>
            Agregador
          </Typography>
          <Grid container spacing={1}>
            {aggregators.map(aggregator => (
              <Grid item xs key={aggregator.name}>
                <FormControlLabel
                  label={
                    {
                      checkInExternalCompany: 'FitPass',
                    }[aggregator.name]
                  }
                  control={
                    <Switch
                      checked={aggregator.enabled}
                      name={aggregator.name}
                      onChange={onAgregatorSwitchChangeHandler(aggregator)}
                      color="primary"
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {!editable && (
        <>
          <Divider className={classes.divider} />
          <Typography variant="h4" className={classes.title}>
            Contraseña
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6} sm={12}>
              <Controller
                name="password"
                defaultValue=""
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    type={passwordStatus ? 'text' : 'password'}
                    label="Contraseña"
                    value={value}
                    error={!!error}
                    onChange={onChange}
                    helperText={error ? error.message : 'Escribe tu contraseña'}
                    InputProps={{
                      autoComplete: 'new-password',
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            setPasswordStatus(prevState => !prevState)
                          }
                        >
                          {passwordStatus ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Controller
                name="confirmPassword"
                defaultValue=""
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    type={passwordConfirmStatus ? 'text' : 'password'}
                    label="Confirma contraseña"
                    value={value}
                    error={!!error}
                    onChange={onChange}
                    helperText={
                      error ? error.message : 'Confirma tu contraseña'
                    }
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            setPasswordConfirmStatus(prevState => !prevState)
                          }
                        >
                          {passwordConfirmStatus ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Box pt={4} textAlign="right">
        <Button
          type="submit"
          color="primary"
          variant="contained"
          loading={isUpdateLoading || isCreateLoading}
        >
          {editable ? 'Actualizar' : 'Agregar'}
        </Button>
      </Box>
      {editable && (
        <>
          <Divider className={classes.divider} />
          <Typography variant="h4" className={classes.title}>
            Horario de atención para pruebas Inbody
          </Typography>
          <Typography className={classes.paragraph}>
            Por favor indica los días y horarios en que los clientes externos
            pueden acudir a realizarse escaneos corporales.
          </Typography>
          <WorkdayScheduleTable />
          <br />
          <Controller
            defaultValue=""
            control={control}
            rules={{
              validate: () => {
                return (
                  hasError || 'Debes de agregar al menos un horario de atención'
                );
              },
            }}
            name="hiddenWorkDays"
            render={({ fieldState: { error } }) => (
              <TextField
                fullWidth
                type="hidden"
                error={!!error}
                helperText={error ? error.message : ' '}
              />
            )}
          />
          <br />
          <br />
          <br />
          <Typography variant="h4" className={classes.title}>
            Días sin servicio
          </Typography>
          <Typography className={classes.paragraph}>
            Por favor indica los días y horarios del año en los que no será
            posible que los clientes acudan a realizarse escaneos corporales.
          </Typography>
          <DayOffScheduleTable />
        </>
      )}
    </form>
  );
};

export default PartnerForm;
