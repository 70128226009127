import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  scheduleTimeId: '',
  aggregators: [],
  currentAggregator: {},
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setPartnerData: (state, action) => {
      state.data = action.payload;
    },
    setScheduleTimeId: (state, action) => {
      state.scheduleTimeId = action.payload;
    },
    setPartnerAggregator: (state, action) => {
      const previous = state.aggregators.filter(
        aggregator => aggregator.name !== action.payload.name,
      );
      previous.push(action.payload);
      state.aggregators = previous;
    },
    setPartnerAggregators: (state, action) => {
      state.aggregators = action.payload;
    },
    setCurrentAggregator: (state, action) => {
      state.currentAggregator = action.payload;
    },
  },
});

export const {
  setPartnerData,
  setScheduleTimeId,
  setPartnerAggregator,
  setCurrentAggregator,
  setPartnerAggregators,
} = partnerSlice.actions;

export const PartnerReducer = partnerSlice.reducer;
