import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';

import { useDeletePartnerInbodyPass } from 'hooks/partners.hook';

const RemovePartnerPassPanel = () => {
  const { id, name } = useSelector(state => state.client);

  const dispatch = useDispatch();

  const { mutate, isLoading } = useDeletePartnerInbodyPass();

  const onAcceptHandler = () => {
    mutate(id);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'REMOVE_PASSES',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>¿Estás seguro de quitar un pase a {name}?</Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default RemovePartnerPassPanel;
