import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import { Box, TextField, Typography } from '@material-ui/core';

import { usePasses } from 'hooks/payments.hook';

import Button from '../../Button/Button.component';

const AddPassesForm = () => {
  const { id, name, key } = useSelector(state => state.client);

  const { mutate, isLoading } = usePasses(key);

  const { control, handleSubmit } = useForm();

  const onSubmitHandler = data => {
    mutate({
      partnerUserId: id,
      quantity: data.amount,
    });
  };

  return (
    <>
      <Typography>Confirma los pases que deseas enviar a {name}</Typography>
      <Box pt={2} component="section">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Controller
            name="amount"
            defaultValue="1"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                type="number"
                value={value}
                onChange={onChange}
                variant="outlined"
                inputProps={{ min: 1, max: 100 }}
              />
            )}
          />
          <Box pt={2} textAlign="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              Confirmar
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddPassesForm;
