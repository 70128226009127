import { forwardRef } from 'react';

import { Box, Typography } from '@material-ui/core';

const Footer = (_, ref) => (
  <Box
    py={3}
    ref={ref}
    textAlign="center"
    color="secondary.main"
    bgcolor="primary.main"
    component="footer"
  >
    <Typography variant="body2">Copyright 2021</Typography>
  </Box>
);

export default forwardRef(Footer);
