import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useStyles } from './CoachTable.styles';

import CoachRow from '../../Rows/Coach/CoachRow.component';

const CoachTable = ({ coaches }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cellHeader}>
              Nombre
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Email
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Teléfono
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {coaches.map(coach => (
            <CoachRow key={coach.id} coach={coach} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CoachTable;
