import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';

import { useStatus } from 'hooks/common.hook';

import { STATUSES } from 'utils/constants';

import { Switch, useStyles } from './CoachRow.styles';

const CoachTableRow = ({ coach }) => {
  const { mutate, isLoading } = useStatus('GET_COACHES');

  const { url } = useRouteMatch();
  const { push } = useHistory();

  const classes = useStyles();

  const onShowDetailsClickHandler = id => event => {
    event.preventDefault();
    push(`${url}/${id}`);
  };

  const onSwitchChangeHandler = id => event => {
    const currentStatus = event.target.value;
    mutate({
      id,
      data: {
        status: {
          active: 'inactive',
          inactive: 'active',
        }[currentStatus],
      },
    });
  };

  return (
    <TableRow key={coach.id} className={classes.row}>
      {/* --- Nombre --- */}
      <TableCell align="center">
        <Typography variant="body2">
          {coach.name} {coach.lastName}
        </Typography>
      </TableCell>
      {/* --- Email --- */}
      <TableCell align="center">
        <Typography variant="body2">{coach.email}</Typography>
      </TableCell>
      {/* --- Pases --- */}
      <TableCell align="center">
        <Typography variant="body2">{coach.phoneNumber}</Typography>
      </TableCell>
      {/* --- Acciones --- */}
      <TableCell align="center">
        <Tooltip title="Ver detalles">
          <IconButton
            className={classes.action}
            onClick={onShowDetailsClickHandler(coach._id)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={STATUSES[coach.status].title}>
          <Switch
            color="primary"
            disabled={isLoading}
            value={coach.status}
            checked={STATUSES[coach.status].value}
            onChange={onSwitchChangeHandler(coach.id)}
          />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default CoachTableRow;
