import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useStyles } from './NutritionistTable.styles';

import NutritionistRow from '../../Rows/Nutritionist/NutritionistRow.component';

const NutritionistsTable = ({ nutritionists }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.cellHeader}>
              Nombre
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Email
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Pases disponibles
            </TableCell>
            <TableCell align="center" className={classes.cellHeader}>
              Acciones
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nutritionists.map(nutritionist => (
            <NutritionistRow
              key={nutritionist._id}
              nutritionist={nutritionist}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NutritionistsTable;
