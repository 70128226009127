import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useDeletePatient } from 'hooks/patient.hook';

const DeletePatientPanel = () => {
  const { temporalID } = useSelector(state => state.temporal);

  const dispatch = useDispatch();

  const { mutate, isLoading } = useDeletePatient();

  const onAcceptHandler = () => {
    mutate(temporalID);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'DELETE_PATIENT',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>
        ¿Estás seguro que deseas eliminar a este paciente?
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default DeletePatientPanel;
