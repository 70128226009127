import { wellnub } from '../wellnub.api';

export const getMonthlyReports = async params => {
  const { data } = await wellnub.get(
    `/v1/admin/inbodyPass/report/${params.type}?buyMonth=${params.month}&buyYear=${params.year}`,
  );
  return data;
};

export const getAdminActivityLogs = async params => {
  const { data } = await wellnub.get(
    `/v1/admin/activityLog/report?month=${params.month}&year=${params.year}`,
  );
  return data;
};

export const getClientData = async query => {
  const queryEncoded = query.replace('+', '%2B');
  const { data } = await wellnub.get(
    `/v1/admin/patient/search?query=${queryEncoded}`,
  );
  return data;
};

export const sendInbodyResults = async id => {
  const { data } = await wellnub.post(
    `/v1/admin/inbodyResult/emailResult/${id}`,
  );
  return data;
};

export const getPatientsAmount = async () => {
  const { data } = await wellnub.get(`/v1/admin/patient/total`);
  return data;
};

export const getUpcomingInbodies = async () => {
  const { data } = await wellnub.get(`/v1/admin/inbodyPass/current`);
  return data;
};

export const getPastInbodies = async () => {
  const { data } = await wellnub.get(`/v1/admin/inbodyPass/past`);
  return data;
};

export const deleteUpcomningInbody = async id => {
  return wellnub.delete(`/v1/admin/inbodyPass/cancel/${id}`);
};

export const postDiscountCampaing = async payload => {
  const { data } = await wellnub.post('/v1/admin/payment/promotion', payload);
  return data;
};

export const getDiscountCampaings = async () => {
  const { data } = await wellnub.get('/v1/admin/payment/promotion');
  return data;
};

export const deleteDiscountCampaing = async id => {
  const { data } = await wellnub.delete(`/v1/admin/payment/promotion/${id}`);
  return data;
};

export const getInterestFreeList = async () => {
  const { data } = await wellnub.get('/v1/admin/payment/instalment');
  return data;
};

export const patchInterestFree = async payload => {
  const { data } = await wellnub.patch(
    `/v1/admin/payment/instalment/${payload.id}`,
    payload.data,
  );
  return data;
};

export const validateClient = async id => {
  const { data } = await wellnub.patch(`/v1/admin/patient/validate/${id}`);
  return data;
};

export const postCreateCheckInInbody = async id => {
  const { data } = await wellnub.post(`/v1/admin/inbodyPass/usePass/${id}`);
  return data;
};

export const deleteCancelCheckInInbody = async id => {
  const { data } = await wellnub.delete(`/v1/admin/inbodyPass/usePass/${id}`);
  return data;
};
