import { configureStore } from '@reduxjs/toolkit';

import { authenticationReducer } from './slices/authentication.slice';
import { modalReducer } from './slices/modal.slice';
import { clientReducer } from './slices/client.slice';
import { PartnerReducer } from './slices/partner.slice';
import { PatientReducer } from './slices/patient.slice';
import { UpcomingEventsReducer } from './slices/upcomingEvents.slice';
import { DiscountsReducer } from './slices/discounts.slice';
import { TemporalReducer } from './slices/temporal.slice';
import { InbodyResultReducer } from './slices/inbodyResultFile.slice';
import { NutritionistReducer } from './slices/nutritionist.slice';
import { WNNotificationsReducer } from './slices/notifications.slice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    modal: modalReducer,
    client: clientReducer,
    partner: PartnerReducer,
    patient: PatientReducer,
    upcomingEvents: UpcomingEventsReducer,
    discounts: DiscountsReducer,
    temporal: TemporalReducer,
    inbodyResultFile: InbodyResultReducer,
    nutritionist: NutritionistReducer,
    wnNotifications: WNNotificationsReducer,
  },
});
