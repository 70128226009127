import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { batch, useDispatch } from 'react-redux';

import {
  addPatientPasses,
  updatePatient,
  deletePatient,
  uploadInBodyResults,
} from 'network/services/patient.service';

import { setModalStatus } from '../redux/slices/modal.slice';
import { setPatientData } from '../redux/slices/patient.slice';

export const useUpdatePatient = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(updatePatient, {
    onSuccess: () => {
      enqueueSnackbar(
        'Se actualizó correctamente la información del paciente',
        {
          variant: 'success',
        },
      );
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

export const useAddPatientPasses = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(addPatientPasses, {
    onSuccess: () => {
      enqueueSnackbar('Se agregaron correctamente los pases al paciente', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'ADD_PATIENT_PASS',
          isActive: false,
        }),
      );
    },
  });
};

export const useDeletePatient = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(deletePatient, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PATIENTS_AMOUNT');
      enqueueSnackbar('Se eliminó correctamente el paciente', {
        variant: 'success',
      });
      dispatch(setPatientData({}));
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intente de nuevo más tarde.',
          {
            variant: 'error',
          },
        );
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_PATIENT',
          isActive: false,
        }),
      );
    },
  });
};

export const useUploadInBodyResults = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(uploadInBodyResults, {
    onSuccess: () => {
      enqueueSnackbar(
        'Se actualizó correctamente la información del paciente',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intente de nuevo más tarde.',
          {
            variant: 'error',
          },
        );
      }
    },
    onSettled: () => {
      batch(() => {
        dispatch(setPatientData({}));
        dispatch(
          setModalStatus({
            name: 'INBODY_RESULT_VIEWER',
            isActive: false,
          }),
        );
      });
    },
  });
};
